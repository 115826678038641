import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: false
};

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    Connectwallet:(state,action)=>{
      state.wallet = action.payload;

    }
  }
});

export const {Connectwallet} = WalletSlice.actions;

export default WalletSlice.reducer;
