import { Network } from "alchemy-sdk";

// export const ethAlchemyNetwork = Network.ETH_MAINNET;
export const alchemyNetwork = {
  1: Network.ETH_MAINNET,
  137: Network.MATIC_MAINNET,
};

// export const ethAlchemyApiKey = "WwkR7OgUeWBsKscvvruAsi7aSizCV5yk";

export const alchemyApiKey = {
  1: "WwkR7OgUeWBsKscvvruAsi7aSizCV5yk",
  137: "QxrsctzBA3_AORIxj-a5E0VdFJJ4DWZf",
};

export const ttTokenAddress = "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82";
export const nftAddress = "0x04234777Ad292e65235F99624fA0B9f580fFB2B4";

export const marketplaceAddress = "0xa1Bb17985095039BF656dF4ED55a25CE2EfeD9a9";

export const ethMarketplaceAddress = "0x6912B6eefc87e84E2afF1a0d5757863fE8fE7BA4";
export const ethZapperAddress = "0xC4E91A31e276e52C1aAC1CF4748281B3B5bd07A0";
export const zapperAddress = "0xad2c804ef3d1beb4c4467086396ae0016c784ab4";
export const rewardAddress = "0x6859186ee053cb547b532e06dcc540792ae3f389";
export const ethRewardAddress = "0x8da875700e63a5ebbc72e6f1949b345f09199bd2";

export const ethRpcUrl = "https://eth-mainnet.g.alchemy.com/v2/WwkR7OgUeWBsKscvvruAsi7aSizCV5yk";
export const rpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/QxrsctzBA3_AORIxj-a5E0VdFJJ4DWZf";

export const blockExplorerUrl = "https://polygonscan.com";
export const ethBlockExplorerUrl = "https://etherscan.io";

export const ethChainId = "0x1";
export const defaultChainId = "0x89"; // polygon
export const ethChainName = "Mainnet";
export const chainName = "Polygon";

//TODO AARON : replace migrationContractAddress
export const migrationContractAddress = "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82";
