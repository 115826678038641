import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { Connectwallet } from "../redux/WalletSlice";
import wallet1 from "../assets/wallet1.png";
import wallet2 from "../assets/wallet2.png";
import wallet3 from "../assets/wallet3.png";
import { walletsstyle, walletBox, walletclose, walletModel } from "./Styles";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import WarningTsti from "./WarningTsti";
import { warginghandl } from "../redux/WorningSlice";

const ConnetWallet = () => {
  const matchpddis50 = useMediaQuery("(max-width:500px)");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(Connectwallet(false));
  };
  const wallets = useSelector((state) => state.wallet.wallet);

  useEffect(() => {
    if (wallets) {
      setOpen(true);
    }
  }, [wallets]);

  const handeleboles = () => {
    dispatch(warginghandl(true));
  };

  return (
    <>
      <WarningTsti />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...walletModel, width: matchpddis50 ? "65%" : "400px" }}>
            <CloseIcon style={walletclose} onClick={handleClose} />
            <Typography
              variant="body1"
              style={{ textAlign: "center", color: "white", fontSize: "20px", marginBottom: "1rem" }}
            >
              Select Your Wallet
            </Typography>
            <Box style={{ ...walletBox, flexDirection: matchpddis50 ? "column" : "row " }}>
              <Box style={walletsstyle} onClick={handeleboles}>
                <img src={wallet1} alt="icons" />
              </Box>
              <Box style={walletsstyle} onClick={handeleboles}>
                <img src={wallet2} alt="icons" />
              </Box>
              <Box style={walletsstyle} onClick={handeleboles}>
                <img src={wallet3} alt="icons" />
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ConnetWallet;
