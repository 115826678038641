import React from "react";
import { Box, Typography } from "@mui/material";
import Select from "react-select";
import {
  sortableBox,
  BoxIcons,
  selctEhtstyled,
  sortableDdl,
  inputBox,
  inputs,
  sorresponsiv,
} from "./Styles";
import { sortableSelct1, sortSelect2 } from "../assets/Data";
import NorthIcon from "@mui/icons-material/North";
import CachedIcon from "@mui/icons-material/Cached";
import { BiSearch } from "react-icons/bi";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";

const Sortable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = React.useState(Object.fromEntries(searchParams)["Search"] ?? "");
  const matches = useMediaQuery("(max-width:1160px)");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputValue === "" && !searchParams.has("Search")) {
        return;
      }

      setSearchParams({ ...Object.fromEntries(searchParams), "Search": inputValue });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputValue, 500]);

  return (
    <>
      <Box style={sortableBox}>
        <Box style={{ ...inputBox, width: matches ? "100%" : "37%" }}>
          <BiSearch />
          <input type="text" placeholder="Search..." style={inputs} value={inputValue} onChange={handleInputChange} />
        </Box>
        <Box
          style={
            matches
              ? sorresponsiv
              : { display: "flex", gap: "10px", alignItems: "center" }
          }
        >
          {/* {sortableSelct1.map((selcts) => (
            <Select
              className="sortableBox"
              key={selcts.id}
              options={selcts.options}
              defaultValue={selcts.options[0]}
              styles={{
                control: (provided) => ({ ...provided, ...selctEhtstyled }), 
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "#0A2334",
                  primary: "#0A2334",
                },
              })}
            />
          ))}*/ }
          {/*<Typography
            variant="body1"
            style={{
              color: "rgba(0, 0, 0, 0.6)",
              fontWeight: "600",
              width: "65px",
            }}
          >
            Sort by:
          </Typography>*/}
          {sortSelect2.map((selcts) => (
            <Select
              className="sortableDdl"
              key={selcts.id}
              options={selcts.options}
              value={selcts.options.find(
                (a) => a.value === Object.fromEntries(searchParams)["SortBy"]
              ) ?? selcts.options[0]}
              styles={{
                control: (provided) => ({ ...provided, ...sortableDdl }),
              }}
              onChange={(e) => {
                setSearchParams({ ...Object.fromEntries(searchParams), "SortBy": e.value });
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "#0A2334",
                  primary: "#0A2334",
                },
              })}
            />
          ))}
          {/* <Box style={BoxIcons}>
            <NorthIcon />
          </Box>
          <Box style={BoxIcons}>
            <CachedIcon />
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Sortable;
