export const optionsStyled = {
  border: "none",
  background: "#1E3240",
  outline: "none",
  color: "white",
  cursor: "pointer",
};
export const dafualdBoxStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  color: "white",
};
export const sidebarstyled = {
  position: "fixed",
  width: 260,
  background: "#0A2334",
  height: "100vh",
  padding: "10px",
  overflow: "auto",
  transition: "all 0.4s",
};
export const closeuIcons = {
  position: "relative",
  top: "-47px",
  left: "234px",
  color: "whire",
  cursor: "pointer",
};
export const menuIcons = {
  position: "absolute",
  top: "68px",
  left: 0,
  color: "whire",
  cursor: "pointer",
  background: "rgba(0,0,0,0.65",
  padding: "2px 5px 0px 5px",
  borderRadius: "5px",
  width: "fit-content",
};
export const allgatagorystyle = {
  color: "white",
  display: "flex",
  cursor: "pointer",
  width: "fit-content",
};
export const MinHomeBox = {
  minHeight: "25vh",
  maxHeight: "25vh",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};
export const typoGraph1 = {
  fontSize: "60px",
  fontWeight: "600",
  background: "rgba(0, 0, 0, 0.42)",
  padding: "10px",
  width: "fit-content",
  borderRadius: "6px",
  color: "white",
  fontFamily: "Audiowide",
};
export const BoxEth = {
  background: "rgba(0, 0, 0, 0.72)",
  padding: "10px",
  width: "fit-content",
  color: "white",
  display: "flex",
  gap: "20px",
  borderRadius: "6px",
  alignItems: "center",
};
export const heroBox = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "93%",
  justifyContent: "end",
  width: "100%",
};

export const NavBox = {
  display: "flex",
  gap: "20px",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
};
export const MaxMInbtn = {
  width: "100px",
  background: "#1E3240",
  textTransform: "capitalize",
};
export const iconsBox = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  margin: "10px auto",
  width: "fit-content",
};

export const sortableBox = {
  display: "flex",
  gap: "10px",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "nowrap",
};

export const BoxIcons = {
  padding: "10px 10px 0px 10px",
  borderRadius: " 5px",
  color: "white",
  background: "rgba(0, 0, 0, 0.6)",
  cursor: "pointer",
};

export const selctEhtstyled = {
  width: "185px",
  background: "rgba(0, 0, 0, 0.6) !important",
  color: "white",
  cursor: "pointer",
  outline: "none",
};

export const sortableDdl = {
  display: "flex",
  color: "white",
  width: "155px",
  cursor: "pointer",
  outline: "none",
};

export const inputBox = {
  display: "flex",
  alignItems: "center",
  height: "27px",
  background: "#1e3240",
  padding: "5px 5px 5px 15px",
  borderRadius: "5px",
  color: "white",
};
export const inputs = {
  border: "none",
  padding: "8px",
  width: "90%",
  outline: "none",
  background: "transparent",
  color: "white",
  fontSize: "16px",
};
export const priceBox = {
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem",
};

export const flexbox = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0.3rem",
};

export const sorresponsiv = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};
export const tabsStyle = {
  color: "white",
  textTransform: "capitalize",
};
export const cardpoints = {
  background: "rgba(109, 110, 112, 0.2)",
  padding: "10px",
  marginTop: "1rem",
};

export const popupBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "70vh",
  background: "#0A2334",
  p: 4,
  borderRadius: "10px",
  color: "white",
  outline: "none",
  overflow: "auto",
};

export const cartPopupBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "70vh",
  background: "#0A2334",
  p: 4,
  borderRadius: "10px",
  color: "white",
  outline: "none",
  overflow: "auto",
};


export const kigsname = {
  background: "rgba(109, 110, 112, 0.40)",
  display: "flex",
  alignItems: "center",
  gap: "20px",
  // padding: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderRadius: "8px",
  width: "200px",
  justifyContent: "space-around",
};

export const stptag = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};
export const kigsown = {
  background: "rgba(109, 110, 112, 0.40)",
  display: "flex",
  alignItems: "center",
  gap: "20px",
  padding: "1rem",
  borderRadius: "8px",
  width: "200px",
  justifyContent: "space-around",
};
export const mainBoxkig = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "20px",
  width: "100%",
  margin: "1rem auto",
};
export const forsalBox = {
  display: "flex",
  paddingTop: "10px",
  alignItems: "left",
  justifyContent: "space-between",
  background: "black",
  padding: "10px 20px",
};

export const forsalechildBox = {
  background: "#6D6E70",
  display: "flex",
  alignItems: "left",
  justifyContent: "space-between",
  boxShadow: "0px -1px 0px 0px rgba(17, 17, 17, 0.08) inset",
  padding: "8px",
};
export const lsticonpopup = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};
export const popupcloseincon = {
  position: "relative",
  right: "-20px",
  cursor: "pointer",
  color: "#6D6E70",
};

export const cartpopupcloseincon = {
  position: "relative",
  cursor: "pointer",
  color: "#FFFFFF",
};
export const closeBox = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "-20px",
};
export const poupbtn = {
  display: "flex",
  alignItems: "center",
  justifyContent:"space-around",
  gap: "30px",
  width: "100%",
};
export const poupbtnrow = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "30px",
  width: "100%",
};
export const popupcard2 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: "457px",
  background: "rgba(109, 110, 112, 0.40)",
};
export const popupbox2border = {
  border: "1px solid white",
  padding: "10px",
  borderRadius: "8px",
  width: "100%",
};
export const Boxesmainbox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  gap: "20px",
  margin: "2rem auto",
};
export const BuySellbox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  gap: "20px",
  marginTop: "1rem",
};
export const walletBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
};
export const walletsstyle = {
  background: "rgba(255, 255, 255, 0.23)",
  borderRadius: "8px",
  padding: "2rem",
  cursor: "pointer",
  textAlign: "center",
  width: "70%",
};
export const walletclose = {
  color: "white",
  cursor: "pointer",
  position: "absolute",
  right: 10,
  top: 10,
};
export const walletModel = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#0A2334",
  p: 4,
  outline: "none",
  border: "none",
  borderRadius: "10px",
  color: "white",
};

export const navButtons = {
  my: 2,
  color: "white",
  display: "block",
  textTransform: "capitalize",
  fontSize: "17px",
  margin: "8px 8px",
  textAlign: "center",
};

export const navButtonsDark = {
  my: 2,
  color: "black",
  display: "block",
  textTransform: "capitalize",
  fontSize: "15px",
};
