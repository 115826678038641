import React from "react";
import { Box, Typography, Button } from "@mui/material";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Select from "react-select";
import { optionsSelect } from "../assets/Data";
import { useSearchParams } from "react-router-dom";
import {
  optionsStyled,
  dafualdBoxStyle,
  sidebarstyled,
  allgatagorystyle,
  MaxMInbtn,
  iconsBox,
  closeuIcons,
} from "./Styles";
import ttLogo from "../assets/teamtoken-no-slogan-light.png";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";
import iconDiscord from "../assets/discord.png";
import iconTelegram from "../assets/TelegramTiny.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toggleSidebar } from "../redux/SidbarSlice";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import imglogo from "../assets/logo.png";

const Sidbar = () => {
  const dispatch = useDispatch();
  const setbar = useSelector((state) => state.sidebar.sidebar);
  const matches = useMediaQuery("(max-width:900px)");
  const [searchParams, setSearchParams] = useSearchParams();
  const handelbar = () => {
    dispatch(toggleSidebar(false));
  };

  return (
    <Box
      style={{
        ...sidebarstyled,
        left: setbar === false ? (matches ? "-280px" : 0) : 0,
        zIndex: matches ? "10000" : "10",
      }}
    >
      <Box style={{ margin: "1rem 0px", display: matches ? "block" : "none" }}>
        <img src={imglogo} alt="Logo" />
      </Box>
      <Box style={{ ...allgatagorystyle, marginTop: matches ? "1rem" : "5rem" }}>
        {/* <ArrowBackIosNewIcon style={{ height: "15px", marginTop: "3px" }} /> */}
        {/* <Typography variant="body1">All Categories</Typography> */}
      </Box>

      <Box style={{ color: "white" }} className="closeIconsidebar">
        <CloseIcon style={closeuIcons} onClick={handelbar} />
      </Box>

      <Box style={{ margin: "2rem auto", width: "fit-content" }}>
        <img src={ttLogo} alt="logo" />
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {optionsSelect.map((apti, index) => (
          <Box key={index} style={dafualdBoxStyle}>
            <label>{apti.label}</label>
            <Select
              options={apti.options}
              value={
                apti.options.find((a) => a.value === Object.fromEntries(searchParams)[apti.label]) ?? apti.options[0]
              }
              styles={{
                control: (provided) => ({ ...provided, ...optionsStyled }),
              }}
              onChange={(e) => {
                setSearchParams({ ...Object.fromEntries(searchParams), [apti.label]: e.value });
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "#0A2334",
                  primary: "#0A2334",
                },
              })}
            />
          </Box>
        ))}
        {/*<Box style={dafualdBoxStyle}>
          <label>Price</label>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" style={MaxMInbtn}>
              {" "}
              Min
            </Button>
            -
            <Button variant="contained" style={MaxMInbtn}>
              {" "}
              Max
            </Button>
          </Box>
          <FormControlLabel
            control={<Checkbox style={{ color: "rgb(30, 50, 64)" }} />}
            label="Favorites"
          />
        </Box>*/}
        <Button
          variant="contained"
          style={{ background: "#F70329", textTransform: "capitalize" }}
          onClick={() => {
            setSearchParams({});
          }}
        >
          Clear Filters
        </Button>
        <Box style={{ margin: "3rem auto" }}>
          {/*<Typography variant="body1" style={{ color: "white" }}>
            Total TeamNFTs: 3,200
          </Typography>*/}
          <Box style={iconsBox}>
            <Link to={"https://teamtoken.com/faq/"}>
              <Typography variant="body2">FAQ</Typography>
            </Link>
            <Link to={"https://teamtoken.com/privacy"}>
              <Typography variant="body2">Privacy</Typography>
            </Link>
            <Link to={"https://teamtoken.com/terms"}>
              <Typography variant="body2">Terms</Typography>
            </Link>
          </Box>
          <Box style={iconsBox}>
            <a href="https://t.me/teamtoken" target="_blank" rel="noreferrer">
              <img src={iconTelegram} alt="icons" style={{ cursor: "pointer" }} />
            </a>
            <a href="https://discord.teamtoken.com/" target="_blank" rel="noreferrer">
              <img src={iconDiscord} alt="icons" style={{ cursor: "pointer" }} />
            </a>
            <a href="https://twitter.com/TeamTokenCrypto" target="_blank" rel="noreferrer">
              <TwitterIcon
                style={{
                  opacity: "0.6",
                  cursor: "pointer",
                }}
              />
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidbar;
