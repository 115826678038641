import { Box, Modal, Button, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Web3TeamTokenContext } from "../context/Web3TeamToken";
import { marketplaceAddress, ttTokenAddress, migrationContractAddress, nftAddress } from "../config";

import { closeBox, cartPopupBox, popupcloseincon } from "./Styles";

function MigrationModal({ open, onClose, ethBalance }) {
  const [migrationStarted, setMigrationStarted] = useState(false);
  const {
    approveContract,
    buyTeamNft,
    isMigrationApproved,
    depositAddress,
    migrateToPolygon,
    balance,
    switchToEthChain,
    currentChainId,
    walletAddress,
    price: usdPrice,
  } = React.useContext(Web3TeamTokenContext);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="w-4/5 p-6 migrationPopupBox md:w-2/3 lg:w-1/2">
        <Box
          style={closeBox}
          onClick={() => {
            console.log("Close icon clicked!");
            onClose();
          }}
        >
          <CloseIcon style={popupcloseincon} />
        </Box>
        <Typography variant="h4" className="pb-4">
          Polygon Migration
        </Typography>
        {/* Migration Notice */}
        <Typography variant="body1" className="pb-6">
          You currently have {Math.floor(ethBalance / 10 ** 18)} TeamToken on Ethereum network. You may now migrate
          these tokens to Polgon with the option below:
        </Typography>
        {/* Centered Button */}
        {currentChainId && currentChainId != 1 ? (
          <Box className="flex justify-center w-full p-4">
            <Button
              variant="contained"
              style={{ background: "#F70329" }}
              onClick={() => {
                if (currentChainId && Number(currentChainId) !== Number(1)) {
                  switchToEthChain();
                  return;
                }
              }}
            >
              Switch to ETH Mainnet
            </Button>
          </Box>
        ) : (
          <Box className="flex justify-center w-full p-4">
            <Button
              variant="contained"
              style={{ background: "#F70329" }}
              onClick={() => {
                migrateToPolygon(depositAddress.address, ethBalance);
              }}
            >
              {migrationStarted ? "Migrating..." : `Transfer ${Math.floor(ethBalance / 10 ** 18)} TT to Polygon`}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default MigrationModal;
