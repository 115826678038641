import React, { useEffect, useState } from "react";
import { AlchemyApiContext } from "../context/AlchemyApiContext";
import { Box, Button, Typography } from "@mui/material";
import { priceBox } from "./Styles";
import { Link } from "react-router-dom";
import { cardpoints } from "./Styles";
import CardDetailsPopup from "./CardDetailsPopup";
//import EthImg from "../assets/Eth.png";
import imgTTtiny from "../assets/TT-tiny.png";
import { sportsMapping } from "../assets/Data";
import { useSearchParams } from "react-router-dom";
import { Web3TeamTokenContext } from "../context/Web3TeamToken";
import { applyNftFilters } from "../util/nftFilters";
import walitimg from "../assets/walit.png";
import { chainId } from "../config";
import { useAccount } from "wagmi";

const Cards = ({ onlyMine = false }) => {
  const [val, setVal] = useState(null);
  const [carddata, setCarddata] = useState([]);
  const { getNfts } = React.useContext(AlchemyApiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { ownedNfts, price, usersRewards, claimRewards, web3Connect } = React.useContext(Web3TeamTokenContext);
  const [fetchedData, setFetchedData] = useState(null);
  const [fetchedRewards, setFetchedRewards] = useState(null);
  const { address: walletAddress, isConnecting, isDisconnected } = useAccount();


  function sumUpSales(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].sellQuantity - arr[i].buyQuantity;
    }
    return sum;
  }

  function abbreviateAddress(walletAddress, beforeLength = 6, afterLength = 4) {
    return (
      walletAddress.substring(0, beforeLength) +
      "..." +
      walletAddress.substring(walletAddress.length - afterLength, walletAddress.length)
    );
  }

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const interval = setInterval(() => {
      usersRewards(walletAddress).then((rewards) => {
        setFetchedRewards(rewards);
      });
    }, 60000);

    usersRewards(walletAddress).then((rewards) => {
      setFetchedRewards(rewards);
    });

    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    Promise.all([
      fetch("https://teamtoken-api-hi57jz64pa-uc.a.run.app/sales").then((response) => response.json()),
      getNfts(onlyMine ? (walletAddress ? walletAddress : "0x0000000000000000000000000000000000000001") : null),
    ]).then(([salesData, nftData]) => {
      setFetchedData({ salesData, nftData });
    });
  }, [walletAddress]);

  useEffect(() => {
    if (!fetchedData) {
      return;
    }

    setCarddata(
      applyNftFilters(
        fetchedData.salesData,
        fetchedData.nftData.nfts ?? fetchedData.nftData.ownedNfts ?? [],
        searchParams
      )
    );
  }, [searchParams, fetchedData]);

  const handelpopup = (item) => {
    setVal(item);
  };
  return (
    <>
      {onlyMine && !walletAddress && (
        <>
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              background: "#F70329",
              border: "1px solid red",
              width: "180px",
              textTransform: "capitalize",
            }}
            //onClick={handelWallet}
            onClick={() => {
              web3Connect();
            }}
          >
            {" "}
            <img src={walitimg} alt="images" /> &nbsp; Connect Wallet
          </Button>
          <Typography className="fontset" fontSize={35} fontWeight={600}>
            {"Don't stay on the sidelines, champion! Connect your wallet now and draft " +
              "your elite TeamNFT squad in the "}
            <a style={{ color: "black", textDecorationLine: "underline" }} href="https://app.teamtoken.com/marketplace">
              Marketplace.
            </a>
            {" It's game time!"}
          </Typography>
        </>
      )}

      {onlyMine && walletAddress && carddata.length == 0 && (
        <>
          <Typography className="fontset" fontSize={35} fontWeight={600}>
            {"Don't stay on the sidelines, champion! Head to the TeamNFT  "}

            <a style={{ color: "black", textDecorationLine: "underline" }} href="https://app.teamtoken.com/marketplace">
              Marketplace
            </a>

            {"."}
          </Typography>
        </>
      )}
      {onlyMine && walletAddress && carddata.length > 0 && (
        <Typography
          variant="body1"
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.3em", marginBottom: "0.5em" }}
        >
          {fetchedRewards && fetchedRewards.rewardsEarned > fetchedRewards.rewardsClaimed ? (
            <>
              You can claim {fetchedRewards.rewardsEarned - fetchedRewards.rewardsClaimed} TT rewards
              <Button
                variant="contained"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  background: "#F70329",
                  border: "1px solid red",
                  width: "160px",
                }}
                onClick={() => claimRewards()}
              >
                Claim Rewards
              </Button>
            </>
          ) : (
            "Rewards are processed Monday after the final game of the week. You don't currently have pending rewards."
          )}
        </Typography>
      )}

      <div className="grid-container">
        {carddata.map((item, index) => (
          <div key={index} className="grid-box" onClick={(e) => handelpopup(item)}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "auto",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <Typography variant="body1" style={{}}>
                {ownedNfts[item.tokenId] && ownedNfts[item.tokenId] + " Owned | "}
                {!item.comingSoon && sumUpSales(item.sales) + " Available"}
              </Typography>
              <Typography variant="body1" style={{}}></Typography>
            </Box>
            {item.comingSoon && (
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  marginBottom: "0.5em",
                  marginTop: "0px",
                }}
              >
                {item.startTime ? "Available " + item.startTime.format("MMM Do YYYY h:mma") : "AVAILABLE SOON!"}
              </Typography>
            )}
            <Box style={{ margin: "auto" }}>
              <img src={item.image} alt="" style={{ width: "256px" }} />
            </Box>
            <Box style={priceBox}>
              <Typography variant="body1" style={{ width: "78%" }}>
                {item.name}
              </Typography>
              <Box>
                <div>
                  <Typography variant="body1">
                    <img src={imgTTtiny} alt="icons" />
                    {new Intl.NumberFormat("en-GB").format(item.priceparsentage)}
                  </Typography>
                  <Typography variant="body2" style={{ color: "rgba(255, 255, 255, 0.73)", textAlign: "right" }}>
                    ${(Number(item.priceparsentage) * price).toFixed(2)}
                  </Typography>
                </div>
              </Box>
            </Box>
          </div>
        ))}
      </div>
      <CardDetailsPopup val={val} setVal={setVal} />
    </>
  );
};

export default Cards;
