export function createSalesOrdersFor(sales, quantityToBuy) {
  if (quantityToBuy === 0) {
    return;
  }
  let totalCost = 0;
  let quantityBought = 0;

  var newBulkOrderData = [];

  let quantityLeftToBuy = quantityToBuy;
  for (const pricePoint of sales) {
    const { price, sellQuantity, tokenId } = pricePoint;

    if (quantityLeftToBuy <= 0) {
      break;
    }

    const quantityToBuyAtThisPricePoint = Math.min(sellQuantity, quantityLeftToBuy);
    const totalCostAtThisPricePoint = quantityToBuyAtThisPricePoint * price;

    newBulkOrderData.push({
      sellId: pricePoint._id.sellId,
      tokenId,
      quantity: quantityToBuyAtThisPricePoint,
      totalCostAtThisPricePoint: totalCostAtThisPricePoint / 1000000000000000000,
    });

    totalCost += totalCostAtThisPricePoint;
    quantityBought += quantityToBuyAtThisPricePoint;
    quantityLeftToBuy -= quantityToBuyAtThisPricePoint;
  }

  if (quantityBought < quantityToBuy) {
    //throw new Error('Insufficient quantity available to fulfill the order');
    return;
  }

  return newBulkOrderData;
}
