import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./SidbarSlice";
import WalletSlice from "./WalletSlice";
import WarningSlice from "./WorningSlice";

const store = configureStore({

  reducer:{
    sidebar:sidebarSlice,
    wallet:WalletSlice,
    warning:WarningSlice
  }
});

export default store;