import { Box, Modal, Button, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import imgTTtiny from "../assets/TT-tiny.png";
import imgCart from "../assets/cart-48.png";
import exampleNFT from "../assets/card1.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  popupBox,
  cartPopupBox,
  kigsname,
  stptag,
  kigsown,
  mainBoxkig,
  forsalBox,
  forsalechildBox,
  selctEhtstyled,
  lsticonpopup,
  closeBox,
  poupbtn,
  poupbtnrow,
  popupcloseincon,
  cartpopupcloseincon,
  popupcard2,
  popupbox2border,
  Boxesmainbox,
  BuySellbox,
} from "./Styles";

const handleBuy = (contractDataFromForm) => {
  console.log(contractDataFromForm);
};

function ShoppingCartPopup({ open, onClose, contractData, onRemoveItem }) {
  const width1 = useMediaQuery("(min-width:1400px)");
  const width2 = useMediaQuery("(min-width:900px)");
  const width3 = useMediaQuery("(min-width:600px)");
  let grandTotal = 0;

  // Calculate the grandTotal outside of the JSX structure
  contractData.forEach((order) => {
    grandTotal += order.totalCostAtThisPricePoint;
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...cartPopupBox, width: width1 ? "30vw" : width2 ? "45vw" : "80vw" }}>
        <Box
          style={closeBox}
          onClick={() => {
            console.log("Close icon clicked!"); // Add this
            onClose();
          }}
        >
          <CloseIcon style={popupcloseincon} />
        </Box>
        <Typography variant="h4" style={{ paddingBottom: "15px" }}>
          Cart
        </Typography>
        {contractData.map((order, index) => (
          <div key={index} className="grid-list">
            <Box style={{ padding: "8px", marginBottom: "auto", marginTop: "auto" }}>
              <img src={exampleNFT} alt="" style={{ width: "40px", cursor: "pointer" }} />
            </Box>
            <Typography
              variant="body1"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                width: "500px",
              }}
            >
              {order.tokenId}
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                width: "auto",
              }}
            >
              X{order.quantity}
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                width: width3 ? "120px" : "200px",
                marginRight: width3 ? "-25px" : "-30px",
              }}
            >
              <img src={imgTTtiny} alt="icons" />
              {order.totalCostAtThisPricePoint}
            </Typography>
            <CloseIcon style={cartpopupcloseincon} onClick={() => onRemoveItem(index)} />
          </div>

          // <ul>
          //   <li key={order.sellId}>
          //     ID: {order.sellId}, Token ID: {order.tokenId}, Quantity: {order.quantity},
          // Total Cost: {order.totalCostAtThisPricePoint}
          //   </li>
          // </ul>
        ))}
        {contractData[0] == null ? (
          <>
            <div style={{ display: "block", paddingTop: "50%", textAlign: "center" }}>
              <img
                style={{ display: "block", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}
                src={imgCart}
                alt="cart Icon"
              />
              <span>Your Shopping cart is</span>
              <span style={{ display: "block" }}>EMPTY</span>
            </div>
          </>
        ) : (
          <Box
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              marginLeft: "auto",
              paddingRight: "10px",
            }}
          >
            <Typography
              variant="body1"
              style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto", paddingRight: "2px" }}
            >
              Total =
            </Typography>
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }} id="ttGrandTotal" variant="body1">
              <img src={imgTTtiny} alt="icons" />
              {grandTotal}
            </Typography>

            <Button
              variant="contained"
              style={{ background: "#F70329", marginLeft: "10px" }}
              onClick={() => handleBuy(contractData)}
            >
              Buy now
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default ShoppingCartPopup;
