import React, { useEffect, useState } from "react";
import { AlchemyApiContext } from "../context/AlchemyApiContext";
import { Box, Button, Typography } from "@mui/material";
import { priceBox, flexbox } from "./Styles";
import { cardpoints } from "./Styles";
import CardDetailsPopup from "./CardDetailsPopup";
//import EthImg from "../assets/Eth.png";
import imgTTtiny from "../assets/TT-tiny.png";
import { sportsMapping } from "../assets/Data";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { getNftAttribute } from "../util/nftFilters";

const RewardHistory = () => {
  const [val, setVal] = useState(null);
  const [rewarddata, setRewarddata] = useState([]);
  const [amount, setAmount] = useState(1);
  const { getNfts } = React.useContext(AlchemyApiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const savedTime = "2023-09-08T00:20:00.000Z";
  const formatedDate = new Date(savedTime).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  useEffect(() => {
    setAmount(0);
  }, [open]);

  useEffect(() => {
    Promise.all([fetch("https://api.teamtoken.com/reward").then((response) => response.json())]).then(
      ([rewarddata]) => {
        setRewarddata(
          rewarddata.map((reward) => {
            const now = moment(reward.gametime * 1000);

            const formatedDate = new Date(now).toLocaleString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            });

            return {
              team1: reward.team1,
              team2: reward.team2,
              winner: reward.winner,
              reward_amount: Math.round(reward.reward_amount),
            
              gametime: formatedDate,
            };
          })
          // .sort((a, b) => b.gametime - a.gametime)
        );
      }
    );
  }, []);

  return (
    <>
      <div>
        {[...rewarddata].map((item, index) => (
          <div className="grid-list" key={index}>
            <Box style={flexbox}>
              <Box style={{ marginTop: "auto", marginBottom: "auto", width: "100px" }}>
                <Typography variant="body1">{item.gametime}</Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              style={{ marginTop: "auto", marginBottom: "auto", width: "200px", textAlign: "center" }}
            >
              <h4
                style={{
                  fontSize: "1.2em",
                  fontWeight: "800",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5px",
                }}
              >
                {item.team1}
              </h4>
              <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>VS</h4>
              <h4 style={{ fontSize: "1.2em", fontWeight: "800", marginTop: "5px" }}>{item.team2}</h4>
            </Typography>

            <Box style={flexbox}>
              <Box style={{ marginTop: "auto", marginBottom: "auto", width: "130px" }}>
                <Typography variant="body1">
                  <span style={{ fontWeight: "800" }}>Winning Team: </span> {item.winner}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "800" }}>Rewards for win: </span>
                  {item.reward_amount} TT
                  {/* <img src={imgTTtiny} alt="icons" /> */}
                </Typography>
              </Box>
            </Box>
          </div>
        ))}
      </div>
      <CardDetailsPopup val={val} comingSoon={true} />
    </>
  );
};

export default RewardHistory;
