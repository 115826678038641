import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warning: false
};

const WarningSlice = createSlice({
  name: "warning",
  initialState,
  reducers: {
    warginghandl:(state,action)=>{
      state.warning = action.payload;
    }
  }
});

export const {warginghandl} = WarningSlice.actions;

export default WarningSlice.reducer;
