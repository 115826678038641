import React, { useEffect, useState } from "react";
import { AlchemyApiContext } from "../context/AlchemyApiContext";
import { Box, Button, Typography } from "@mui/material";
import { priceBox } from "./Styles";
import { cardpoints } from "./Styles";
import CardDetailsPopup from "./CardDetailsPopup";
import ShoppingCartPopup from "./ShoppingCartPopup";
//import EthImg from "../assets/Eth.png";
import imgTTtiny from "../assets/TT-tiny.png";
import { sportsMapping } from "../assets/Data";
import { useSearchParams } from "react-router-dom";
import { Web3TeamTokenContext } from "../context/Web3TeamToken";
import { applyNftFilters } from "../util/nftFilters";
import { createSalesOrdersFor } from "../util/OrderHelper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { marketplaceAddress, ttTokenAddress } from "../config";

const CardList = () => {
  const width2 = useMediaQuery("(max-width:1200px)");
  const width1 = useMediaQuery("(max-width:500px)");
  const [val, setVal] = useState(null);
  const [carddata, setCarddata] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const addAmount = (newAmount) => {
    setAmounts((prevAmounts) => [...prevAmounts, newAmount]);
  };
  const { getNfts } = React.useContext(AlchemyApiContext);
  const { buyBulkTeamNft, approveContract, getAllowance, walletAddress } = React.useContext(Web3TeamTokenContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchedData, setFetchedData] = useState(null);
  const [open, setOpen] = useState(false);
  var grandTotal = 0;
  let contractData = [];
  const [allowance, setAllowance] = useState(0);
  function sumUpSales(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].sellQuantity - arr[i].buyQuantity;
    }
    return sum;
  }

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const checkAllowance = () => {
      getAllowance(ttTokenAddress, marketplaceAddress).then((allowance) => {
        setAllowance(allowance);
      });
    };
    const interval = setInterval(() => {
      checkAllowance();
    }, 15000);
    checkAllowance();

    return () => clearInterval(interval);
  }, [walletAddress]);

  function calculateBulkOrderCost(sales, quantityToBuy) {
    var salesOrders = createSalesOrdersFor(sales, quantityToBuy);
    // create a variable for the sum and initialize it
    let sum = 0;

    // iterate over each item in the array

    for (let i = 0; i < salesOrders.length; i++) {
      sum += salesOrders[i].totalCostAtThisPricePoint;
      if (salesOrders[i].quantity > 0) {
        contractData.push(salesOrders[i]);
      }
    }

    grandTotal += sum;

    return sum;
  }

  const handleChange = (event) => {
    setAmounts((prevAmounts) => {
      const newAmounts = [...prevAmounts];
      if (event.target.value < 11) {
        newAmounts[event.target.id] = event.target.value;
      }
      return newAmounts;
    });
  };

  useEffect(() => {
    if (amounts.length === 0) {
      setAmounts([0]);
    }
  }, [amounts]);

  useEffect(() => {
    Promise.all([
      fetch("https://teamtoken-api-hi57jz64pa-uc.a.run.app/sales").then((response) => response.json()),
      getNfts(),
    ]).then(([salesData, nftData]) => {
      setFetchedData({ salesData, nftData });
    });
  }, []);

  useEffect(() => {
    if (!fetchedData) {
      return;
    }
    const newSalesData = fetchedData.salesData;
    const newNftData = fetchedData.nftData.nfts ?? fetchedData.nftData.ownedNfts ?? [];

    const newCardData = applyNftFilters(newSalesData, newNftData, searchParams);

    setCarddata(newCardData);
    setAmounts(new Array(newCardData.length).fill(0));
  }, [searchParams, fetchedData]);

  const handelpopup = (item) => {
    setVal(item);
  };

  // Function to handle modal open
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to handle modal close
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdvancedBuy = () => {
    let sellIds = [];
    let quantitys = [];
    for (let i = 0; i < contractData.length; i++) {
      sellIds[i] = contractData[i].sellId;
      quantitys[i] = contractData[i].quantity;
    }
    buyBulkTeamNft(sellIds, quantitys);
  };

  const handleRemoveItem = (indexToRemove) => {
    contractData = contractData.filter((_, index) => index !== indexToRemove);
  };

  return (
    <>
      <div>
        {carddata.map((item, index) => (
          <div key={index} className="grid-list">
            <Box style={{ paddingLeft: "15px", marginBottom: "auto", marginTop: "auto" }}>
              <img
                src={item.image}
                alt=""
                style={{ width: "40px", cursor: "pointer" }}
                onClick={(e) => handelpopup(item)}
              />
            </Box>
            <Typography
              variant="body1"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                width: width2 ? "230px" : "500px",
              }}
            >
              {item.name}
            </Typography>
            <div style={{ width: "auto" }}> </div>
            <Box
              variant="contained"
              style={{
                background: "rgba(0,0,0,0.00)",
                marginTop: "auto",
                marginBottom: "auto",
                width: "100px",
              }}
            >
              <Button
                style={{
                  background: "#6D6E70",
                  borderRadius: "4px",
                  width: "5px",
                  minWidth: "30px",
                  padding: "0px 4px",
                  color: "#fff",
                }}
                onClick={() => {
                  if (amounts[index] < 1 || amounts[index] > sumUpSales(item.sales)) {
                    return;
                  }
                  setAmounts((prevAmounts) => {
                    const newAmounts = [...prevAmounts];
                    newAmounts[index] = newAmounts[index] - 1;
                    return newAmounts;
                  });
                }}
              >
                -
              </Button>
              <input
                id={index}
                style={{
                  background: "rgba(0,0,0,0.65)",
                  color: "#fff",
                  border: "none",
                  textAlign: "center",
                  height: "20px",
                  width: "30px",
                }}
                type="number"
                min={0}
                max={10}
                value={amounts[index]}
                onChange={handleChange}
              />
              <Button
                style={{
                  width: "5px",
                  minWidth: "30px",
                  background: "#6D6E70",
                  borderRadius: "4px",
                  padding: "0px 4px",
                  color: "#fff",
                }}
                onClick={() => {
                  if (amounts[index] < 0 || amounts[index] >= sumUpSales(item.sales)) {
                    return;
                  }
                  setAmounts((prevAmounts) => {
                    const newAmounts = [...prevAmounts];
                    if (newAmounts[index] < 10) {
                      newAmounts[index] = newAmounts[index] + 1;
                    }
                    return newAmounts;
                  });
                }}
              >
                +
              </Button>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "1rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                width: width2 ? "100px" : "132px",
              }}
            >
              <Box>
                <Typography variant="body1">
                  <img src={imgTTtiny} alt="icons" />
                  {item.priceparsentage}
                </Typography>
                <Typography variant="body2" style={{ color: "rgba(255, 255, 255, 0.73)" }}>
                  Total Price ={" "}
                  {amounts[index] > 0 ? Math.round(calculateBulkOrderCost(item.sales, amounts[index])) : 0}
                </Typography>
              </Box>
            </Box>
          </div>
        ))}
      </div>
      <CardDetailsPopup val={val} comingSoon={true} />
      <Box className="totalList" style={{ marginTop: "auto", marginBottom: "auto" }}>
        <Typography variant="body1" style={{ marginTop: "auto", marginBottom: "auto" }}>
          Total =
        </Typography>
        <Typography style={{ marginTop: "auto", marginBottom: "auto" }} id="ttGrandTotal" variant="body1">
          <img src={imgTTtiny} alt="icons" />
          {Math.round(grandTotal)}
        </Typography>
        <Button
          variant="contained"
          style={{ background: "#F70329", marginLeft: "10px" }}
          onClick={() => {
            // console.log(grandTotal);
            if (allowance < grandTotal) {
              approveContract(ttTokenAddress, marketplaceAddress);
              return;
            }

            handleAdvancedBuy();
          }}
        >
          {allowance >= grandTotal ? "Buy now" : "Approve TT to buy"}
        </Button>
        <ShoppingCartPopup
          open={open}
          onClose={handleClose}
          contractData={contractData}
          onRemoveItem={handleRemoveItem}
        />
      </Box>
      <CardDetailsPopup val={val} setVal={setVal} />
    </>
  );
};

export default CardList;
