import React, { useEffect, useState } from "react";
import imglogo from "../assets/logo.png";
import { Box, Button } from "@mui/material";
import { NavBox, menuIcons, navButtons, navButtonsDark } from "./Styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import walitimg from "../assets/walit.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/SidbarSlice";
import MenuIcon from "@mui/icons-material/Menu";
import ConnetWallet from "./ConnetWallet";
import { Connectwallet } from "../redux/WalletSlice";
import Tooltip from "react-power-tooltip";
import { Web3TeamTokenContext } from "../context/Web3TeamToken";
import { chainId } from "../config";
import MigrationModal from "../components/migrationModal";
import { AlchemyApiContext } from "../context/AlchemyApiContext";

function abbreviateAddress(walletAddress, beforeLength = 6, afterLength = 4) {
  return (
    walletAddress.substring(0, beforeLength) +
    "..." +
    walletAddress.substring(walletAddress.length - afterLength, walletAddress.length)
  );
}

const NavbarApp = () => {
  const setbar = useSelector((state) => state.sidebar.sidebar);
  // const { getEthTTBalance } = React.useContext(AlchemyApiContext);
  const dispatch = useDispatch();
  const [ethTTBalance, setEthTTBalance] = useState(null);
  const matches = useMediaQuery("(max-width:900px)");
  const matcwidth = useMediaQuery("(max-width:400px)");
  const [isModalOpen, setModalOpen] = useState(false);
  const [showNetworkSwitcher, setShowNetworkSwitcher] = useState(false);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const {
    web3Connected,
    currentChainId,
    switchToEthChain,
    walletAddress,
    web3Connect,
    price,
    depositAddress,
    hasETHTTBalance,
  } = React.useContext(Web3TeamTokenContext);

  // useEffect(() => {
  //   console.log("wallet address", walletAddress);
  //   if (walletAddress) {
  //     Promise.all([getEthTTBalance(walletAddress)]).then((balanceData) => {
  //       console.log("balance ", Number(balanceData[0]?.tokenBalances[0]?.tokenBalance));
  //       setEthTTBalance(balanceData[0]);
  //     });
  //   }
  // }, [walletAddress]);

  useEffect(() => {
    if (walletAddress && (currentChainId == 1 || currentChainId == 137)) {
      Promise.all([hasETHTTBalance(walletAddress)]).then((balanceData) => {
        // console.log("balance ", Number(balanceData[0]?.tokenBalances[0]?.tokenBalance));
        // console.log("balance    ", balanceData[0][0].balance);
        if (balanceData.length > 0) {
          // console.log(balanceData[0][0]?.balance / 10 ** 18);
          setEthTTBalance(balanceData[0][0]?.balance);
          if (balanceData[0][0]?.balance > 0) {
            setModalOpen(true);
            setShowNetworkSwitcher(true);
          }
        }
      });
    }
  }, [walletAddress, currentChainId]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handelbar = () => {
    dispatch(toggleSidebar(true));
  };
  const handelWallet = () => {
    dispatch(Connectwallet(true));
  };

  // const { getUserNeedsMigration } = React.useContext(AlchemyApiContext);

  // useEffect(() => {
  //   Promise.all([getUserNeedsMigration(walletAddress ? walletAddress : null)]).then(([NeedsMigration]) => {
  //     if (NeedsMigration == true) {
  //       setModalOpen(true);
  //     }
  //   });
  // }, [walletAddress]);

  const TooltipTT = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <>
        <div
          style={{
            position: "relative",
            display: matches ? "block" : "inline-block",
            margin: matches ? "auto" : "0px",
          }}
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <span style={{ display: matches ? "block" : "inline-block" }}>
            <Button sx={navButtons}>TeamToken</Button>
          </span>
          <div
            style={{
              transition: "opacity 0.3s ease-in-out",
              opacity: showTooltip ? 1 : 0,
            }}
          >
            <Tooltip
              hoverBackground="white"
              hoverColor="#F70329"
              show={showTooltip}
              position="bottom"
              arrowAlign="center"
            >
              <Typography variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/leagues">
                  Leagues
                </a>
              </Typography>
              <Typography variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/About">
                  About
                </a>
              </Typography>
              <Typography variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/whitepaper">
                  Whitepaper
                </a>
              </Typography>
              <Typography variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/Merch">
                  Merch
                </a>
              </Typography>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const TooltipLearn = () => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <>
        <div
          style={{
            position: "relative",
            display: matches ? "block" : "inline-block",
            margin: matches ? "auto" : "0px",
          }}
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <span style={{ display: matches ? "block" : "inline-block" }}>
            <Button style={{ width: "88%" }} sx={navButtons}>
              Learn
            </Button>
          </span>
          <div
            style={{
              transition: "opacity 0.3s ease-in-out",
              opacity: showTooltip ? 1 : 0,
            }}
          >
            <Tooltip show={showTooltip} position="bottom" arrowAlign="center">
              <Typography to="" variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/blog">
                  Blog
                </a>
              </Typography>
              <Typography variant="body1" color="initial">
                <a style={navButtonsDark} href="https://teamtoken.com/faq">
                  Faq
                </a>
              </Typography>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <MigrationModal open={isModalOpen} onClose={() => setModalOpen(false)} ethBalance={ethTTBalance} />
      <AppBar style={{ background: "#0A2334", padding: "0px 10px" }}>
        <Toolbar disableGutters style={NavBox}>
          <Box>
            <img src={imglogo} alt="Logo" style={{ width: matcwidth ? "150px" : "auto" }} />
            <Box
              style={{
                ...menuIcons,
                display: matches ? (setbar === true ? "none" : "block") : "none",
              }}
              onClick={handelbar}
            >
              <MenuIcon />
            </Box>
          </Box>

          <Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                    background: "rgba(0,0,0,0.5)",
                  },
                }}
              >
                <Box
                  style={{
                    background: "rgb(10, 35, 52)",
                    color: "white",
                    padding: "1rem",
                  }}
                >
                  <Button sx={navButtons} href="https://teamtoken.com">
                    Home
                  </Button>
                  <TooltipLearn />
                  {/* <Button sx={navButtons} href="#">
                    MyTeamToken
                  </Button> */}
                  <Button sx={navButtons} href="/marketplace">
                    Marketplace
                  </Button>

                  {/* {web3Connected ? (
                    <Button
                      variant="contained"
                      style={{ background: "#F70329", textTransform: "capitalize" }}
                      //onClick={handelWallet}
                      onClick={() => {
                        if (currentChainId && Number(currentChainId) !== Number(chainId)) {
                          switchToEthChain();
                          return;
                        }

                        web3Connect();
                      }}
                    >
                      {" "}
                      <img src={walitimg} alt="images" /> &nbsp;{" "}
                      {currentChainId && Number(currentChainId) !== Number(chainId)
                        ? "Wrong network"
                        : abbreviateAddress(walletAddress)}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ background: "#F70329", textTransform: "capitalize" }}
                      //onClick={handelWallet}
                      onClick={() => {
                        web3Connect();
                      }}
                    >
                      {" "}
                      <img src={walitimg} alt="images" /> &nbsp; Connect Wallet
                    </Button>
                  )} */}
                  {/* <w3m-network-button /> */}
                  <w3m-button size="sm" balance="hide" />
                </Box>
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button sx={navButtons} href="https://teamtoken.com">
                Home
              </Button>
              <TooltipLearn />
              {/* <Button sx={navButtons} href="#">
                MyTeamToken
              </Button> */}
              <Button sx={navButtons} href="/marketplace">
                Marketplace
              </Button>
            </Box>
          </Box>

          <Box
            style={{
              display: matches ? "none" : "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {/*<Badge badgeContent={""} color="error" variant="dot">*/}
            {/*  <NotificationsOutlinedIcon />*/}
            {/*</Badge>*/}
            <span style={{ marginLeft: "2px", marginRight: "2px" }}>{price ? "$" + price.toFixed(4) : null}</span>
            {/* <span style={{ marginLeft: "2px", marginRight: "2px" }}>{balance ? balance + " TT" : null}</span> */}
            {/* {web3Connected ? (
              <Button
                variant="contained"
                style={{ background: "#F70329", textTransform: "capitalize" }}
                //onClick={handelWallet}
                onClick={() => {
                  // if (currentChainId && Number(currentChainId) !== Number(chainId)) {
                  //   switchToEthChain();
                  //   return;
                  // }

                  web3Connect();
                }}
              >
                {" "}
                <img src={walitimg} alt="images" /> &nbsp;{" "}
                {currentChainId && Number(currentChainId) !== Number(chainId)
                  ? "Wrong network"
                  : abbreviateAddress(walletAddress)}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ background: "#F70329", textTransform: "capitalize" }}
                //onClick={handelWallet}
                onClick={() => {
                  web3Connect();
                }}
              >
                {" "}
                <img src={walitimg} alt="images" /> &nbsp; Connect Wallet
              </Button>
            )} */}

            {showNetworkSwitcher && <w3m-network-button />}

            <w3m-button size="md" balance="show" />
          </Box>
        </Toolbar>
      </AppBar>
      <ConnetWallet />
    </>
  );
};

export default NavbarApp;
