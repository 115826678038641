import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Sidbar from "./components/Sidbar";
import "./App.css";
import { AlchemyApiContextProvider } from "./context/AlchemyApiContext";
import { Web3TeamTokenProvider } from "./context/Web3TeamToken";

// import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { polygon, mainnet } from "wagmi/chains";

const chains = [polygon, mainnet];
const projectId = "afe8f82441d70ffd8cb446f9a3fd9aa7";

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient,
// });

const metadata = {
  name: "TeamToken",
  description: "TeamToken App",
  url: "https://app.teamtoken.com",
  icons: ["https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png"],
};
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
// const ethereumClient = new EthereumClient(wagmiConfig, chains);
createWeb3Modal({
  defaultChain: polygon,
  wagmiConfig,
  projectId,
  chains,
  tokens: {
    // 1: {
    //   address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
    //   image: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png", //optional
    // },
    137: {
      address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
      image: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png", //optional
    },
  },
});

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Web3TeamTokenProvider>
            <AlchemyApiContextProvider>
              <div className="main-App">
                <Sidbar />
                <div className="App">
                  <Routes>
                    <Route path="/marketplace" element={<Home />} />
                    <Route path="/buy" element={<Home />} />
                    <Route path="/" element={<Navigate to="/marketplace" />} />
                  </Routes>
                </div>
              </div>
            </AlchemyApiContextProvider>
          </Web3TeamTokenProvider>
        </BrowserRouter>
      </WagmiConfig>
      {/* <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={polygon}
        themeVariables={{ "--w3m-z-index": "2000" }}
      /> */}
    </>
  );
}

export default App;
