import React, { createContext, useEffect, useState } from "react";
import Web3 from "web3";
import ERC20ABI from "./abi/ERC20.json";
import TTMarketplaceABI from "./abi/TTMarketplace.json";
import TeamNFTABI from "./abi/TeamNFT.json";
import TTZapperABI from "./abi/TTZapper.json";
import TTRewards from "./abi/TTRewards.json";
import { useLocation } from "react-router-dom";
import {
  blockExplorerUrl,
  defaultChainId,
  chainName,
  marketplaceAddress,
  rewardAddress,
  ethRewardAddress,
  nftAddress,
  rpcUrl,
  ttTokenAddress,
  zapperAddress,
} from "../config";
import SwitchChain from "../pages/SwitchChain";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect, useSwitchNetwork, useNetwork, useBalance } from "wagmi";
import { Contract } from "alchemy-sdk";

export const Web3TeamTokenContext = createContext(null);

export function Web3TeamTokenProvider(props: any) {
  const decimals = 18;

  const location = useLocation();
  const { open, close } = useWeb3Modal();
  const [ownedNfts, setOwnedNfts] = useState<{}>({});
  const [depositAddress, setDepositAddress] = useState<String | null>(null);

  const [balance, setBalance] = useState(null);
  const [ethBalance, setEthBalance] = useState(null);
  const [price, setPrice] = useState(null);
  const [ethPrice, setEthPrice] = useState(null);
  const [maticPrice, setMaticPrice] = useState(null);
  const [provider, setProvider] = useState<any>(null);
  const [currentChainId, setCurrentChainId] = useState(null);
  const [web3Connection, setWeb3Connection] = useState<Web3>();
  const [web3Connected, setWeb3Connected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [offline, setOffline] = React.useState(false);
  const { connector: activeConnector, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

  const { data: wagmiTTBalance } = useBalance({
    address: walletAddress as `0x${string}`,
    chainId: 137,
    token: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
  });

  useAccount({
    onDisconnect() {
      window.location.reload();
    },
  });
  const { disconnect } = useDisconnect();

  useEffect(() => {
    const fetchPrice = async () => {
      // const responseRaw = await fetch(
      //   "https://deep-index.moralis.io/api/v2.2/erc20/" + ttTokenAddress + "/price",
      //   {
      //     method: "GET",
      //     headers: {
      //       accept: "*/*",
      //       "X-API-KEY": "WvGuWKYH7cPyN89ykFLq0dJARcewj3TyXpKIEbxrEHvOPKeYl2xJnGsH7BNeeYeU"
      //     }
      //   });
      const responseRaw = await fetch("https://api.coingecko.com/api/v3/coins/teamtoken", {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const price = await responseRaw.json();
      return price.tickers[0].converted_last.usd;
    };

    const interval = setInterval(async () => {
      fetchPrice().then((price) => {
        setPrice(price);
      });
    }, 60000);
    fetchPrice().then((price) => {
      setPrice(price);
    });

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchEthPrice = async () => {
      // const responseRaw = await fetch(
      //   "https://deep-index.moralis.io/api/v2.2/erc20/" + ttTokenAddress + "/price",
      //   {
      //     method: "GET",
      //     headers: {
      //       accept: "*/*",
      //       "X-API-KEY": "WvGuWKYH7cPyN89ykFLq0dJARcewj3TyXpKIEbxrEHvOPKeYl2xJnGsH7BNeeYeU"
      //     }
      //   });
      const responseRaw = await fetch("https://api.coingecko.com/api/v3/coins/ethereum", {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const ethPrice = await responseRaw.json();
      return ethPrice.tickers[0].converted_last.usd;
    };

    const fetchMaticPrice = async () => {
      // const responseRaw = await fetch(
      //   "https://deep-index.moralis.io/api/v2.2/erc20/" + ttTokenAddress + "/price",
      //   {
      //     method: "GET",
      //     headers: {
      //       accept: "*/*",
      //       "X-API-KEY": "WvGuWKYH7cPyN89ykFLq0dJARcewj3TyXpKIEbxrEHvOPKeYl2xJnGsH7BNeeYeU"
      //     }
      //   });
      const responseRaw = await fetch("https://api.coingecko.com/api/v3/coins/matic-network", {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const maticPrice = await responseRaw.json();
      return maticPrice.tickers[0].converted_last.usd;
    };

    const interval = setInterval(async () => {
      fetchEthPrice().then((ethPrice) => {
        setEthPrice(ethPrice);
      });
      fetchMaticPrice().then((maticPrice) => {
        setMaticPrice(maticPrice);
      });
    }, 60000);
    fetchEthPrice().then((ethPrice) => {
      setEthPrice(ethPrice);
    });
    fetchMaticPrice().then((maticPrice) => {
      setMaticPrice(maticPrice);
    });
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const fetchOwnedNfts = async () => {
      const responseRaw = await fetch("https://api.teamtoken.com/wallet/" + walletAddress, {
        method: "GET",
      });
      if (responseRaw.status !== 200) {
        return {};
      }

      const ownedNfts = await responseRaw.json();
      const mappedNfts = {};
      ownedNfts.teamnfts.forEach((nft) => {
        mappedNfts[nft.tokenId] = Number(nft.balance);
      });

      return mappedNfts;
    };

    const interval = setInterval(async () => {
      fetchOwnedNfts().then((ownedNfts) => {
        setOwnedNfts(ownedNfts);
      });
    }, 60000);
    fetchOwnedNfts().then((ownedNfts) => {
      setOwnedNfts(ownedNfts);
    });

    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const fetchDepositAddress = async () => {
      const responseRaw = await fetch(
        "https://bridge-api-hi57jz64pa-ue.a.run.app/address/ethereum/" + walletAddress.toLowerCase(),
        {
          method: "GET",
        }
      );
      if (responseRaw.status !== 200) {
        return {};
      }

      const depositAddressRaw = await responseRaw.json();

      return depositAddressRaw.depositAddress;
    };

    const interval = setInterval(async () => {
      fetchDepositAddress().then((depositAddress) => {
        setDepositAddress(depositAddress);
      });
    }, 60000);
    fetchDepositAddress().then((depositAddress) => {
      setDepositAddress(depositAddress);
    });

    return () => clearInterval(interval);
  }, [walletAddress, currentChainId]);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const fetchBalance = async () => {
      const contract = getContract(ERC20ABI, ttTokenAddress);
      const approvalAmount = await contract.methods.balanceOf(walletAddress).call();

      return approvalAmount / Math.pow(10, decimals);
    };

    const interval = setInterval(async () => {
      fetchBalance().then((balance) => {
        setBalance(String(Math.round(balance)));
      });
    }, 60000);
    fetchBalance().then((balance) => {
      setBalance(String(Math.round(balance)));
    });

    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const fetchETHBalance = async () => {
      const ethbalance = await getAcctBalance(walletAddress);

      return (Number(ethbalance) / Math.pow(10, 18)).toString();
    };

    const interval = setInterval(async () => {
      fetchETHBalance().then((ethBalance) => {
        setEthBalance(String(Number(ethBalance)));
      });
    }, 60000);
    fetchETHBalance().then((ethBalance) => {
      setEthBalance(String(Number(ethBalance)));
    });

    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    if (!activeConnector || !isConnected) {
      setProvider(null);
      return;
    }

    (async () => {
      setProvider(await activeConnector.getProvider());
    })();
  }, [activeConnector, isConnected]);

  const web3Connect = async (connector?: string) => {
    await open();
  };

  const web3Disconnect = async () => {
    await disconnect();
    window.location.reload();
  };

  const switchToEthChain = async () => {
    if (chain.id != 1) {
      switchNetwork?.(1);
    }
  };

  useEffect(() => {
    if (!provider) {
      return;
    }

    const onConnect = async () => {
      setWeb3Connection(null);
      setWeb3Connected(false);
      setWalletAddress(null);
      setCurrentChainId(null);
      setEthBalance(null);
      const web3Connection = new Web3(provider);
      console.log("Connecting wallet"); // TODO REMOVE
      web3Connection.eth.getAccounts().then((accounts: any) => {
        console.log("Connected wallet. Account: ", accounts[0]); // TODO REMOVE
        web3Connection.eth.getChainId().then(function (chainId) {
          if (String(chainId).indexOf("0x") === 0) {
            chainId = parseInt(String(chainId), 16);
          }
          console.log("Found chainId: ", chainId); // TODO REMOVE
          setWeb3Connection(web3Connection);
          setWeb3Connected(true);
          setCurrentChainId(Number(chainId));
          // setWalletAddress(currentChainId && Number(currentChainId) === Number(chainId) ? accounts[0] : null);
          setWalletAddress(chainId == 1 || chainId == 137 ? accounts[0] : null);

          setEthBalance(web3Connection.eth.getBalance(accounts[0]));

          provider.on("disconnect", () => {
            window.location.reload();
          });

          provider.on("accountsChanged", (accounts: string[]) => {
            if (accounts.length <= 0 || accounts[0] !== walletAddress) {
              window.location.reload();
            }
          });
          provider.on("chainChanged", (newChainId: any) => {
            // if (String(newChainId).indexOf("0x") === 0) {
            //   newChainId = parseInt(String(newChainId), 16);
            // }
            //
            // if (Number(currentChainId) !== Number(newChainId)) {
            //   window.location.reload();
            // }
            // console.log("Chain changed: ", chainId); // TODO REMOVE
            // setCurrentChainId(Number(newChainId));

            window.location.reload();
          });
        });
      });
    };
    onConnect();
    provider.on("connect", onConnect);

    return () => {
      provider.removeAllListeners("connect");
      provider.removeAllListeners("disconnect");
      provider.removeAllListeners("accountsChanged");
      provider.removeAllListeners("chainChanged");
    };
  }, [provider]);

  React.useEffect(() => {
    const onlineListener = () => {
      setOffline(false);
    };
    const offlineListener = () => {
      setOffline(true);
    };

    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);
    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", offlineListener);
    };
  }, []);

  const getContract = (abi: any, address: string) => {
    if (!address) {
      throw new Error("No contract address provided");
    }
    return new web3Connection.eth.Contract(abi, address);
  };

  const getAcctBalance = async (address: string) => {
    if (!address) {
      throw new Error("No wallet address provided");
    }
    return await web3Connection.eth.getBalance(address);
  };

  const toPlainString = (num: any) => {
    return ("" + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join("0") + c + d
        : b + c + d + Array(e - d.length + 1).join("0");
    });
  };

  // if (offline) {
  //   return (
  //     <Offline />
  //   );
  // }

  return (
    <Web3TeamTokenContext.Provider
      value={{
        web3Connect,
        web3Disconnect,
        web3Connection,
        web3Connected,
        currentChainId,
        switchToEthChain,
        depositAddress,
        walletAddress,
        provider,
        ownedNfts,
        balance,
        ethBalance,
        price,
        ethPrice,
        maticPrice,
        getBalance: async (contractAddress: string) => {
          return wagmiTTBalance.formatted;
        },
        getAllowance: async (contractAddress: string, spender: string) => {
          const contract = getContract(ERC20ABI, contractAddress);
          const approvalAmount = await contract.methods.allowance(walletAddress, spender).call();

          return approvalAmount / Math.pow(10, decimals);
        },
        approveContract: (
          contractAddress: string,
          spender: string,
          amount: string = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        ) => {
          const contract = getContract(ERC20ABI, contractAddress);
          return contract.methods.approve(spender, amount).send({ from: walletAddress });
        },
        isNftApproved: async (spender: string) => {
          const contract = getContract(TeamNFTABI, nftAddress);
          return await contract.methods.isApprovedForAll(walletAddress, spender).call();
        },
        approveNft: (spender: string) => {
          const contract = getContract(TeamNFTABI, nftAddress);
          return contract.methods.setApprovalForAll(spender, true).send({ from: walletAddress });
        },
        buyTeamNft: (sellId: number, quantity: number) => {
          const contract = getContract(TTMarketplaceABI, marketplaceAddress);
          return contract.methods.buyTeamNFT(sellId, quantity).send({ from: walletAddress });
        },

        buyBulkTeamNft: (sellIds: number[], quantitys: number[]) => {
          const contract = getContract(TTMarketplaceABI, marketplaceAddress);
          return contract.methods.buyBatchTeamNFT(sellIds, quantitys, false).send({ from: walletAddress });
        },
        buyTeamNftETH: (sellId: number, quantity: number, tokenId: number, ethAmount: number) => {
          const contract = getContract(TTZapperABI, zapperAddress);
          const adjustedPrice = toPlainString(ethAmount * 1.1 * Math.pow(10, decimals));
          return contract.methods
            .buyWithETH(sellId, quantity, tokenId)
            .send({ from: walletAddress, value: adjustedPrice });
        },
        cancelList: (sellId: number) => {
          const contract = getContract(TTMarketplaceABI, marketplaceAddress);
          return contract.methods.cancelList(sellId).send({ from: walletAddress });
        },
        listTeamNft: (tokenId: number, quantity: number, price: number) => {
          const contract = getContract(TTMarketplaceABI, marketplaceAddress);
          const adjustedPrice = toPlainString(price * Math.pow(10, decimals));
          return contract.methods
            .listTeamNFT(tokenId, quantity, adjustedPrice, 0, 31536000)
            .send({ from: walletAddress });
        },
        migrateToPolygon: (depositAddress, ethBalance) => {
          const contract = getContract(ERC20ABI, ttTokenAddress);
          return contract.methods.transfer(depositAddress, ethBalance).send({ from: walletAddress });
        },
        async hasNFT(walletAddress: string): Promise<boolean> {
          const nftAddressObj: any = {};
          nftAddressObj[nftAddress] = [];

          const responseRaw = await fetch(
            "https://deep-index.moralis.io/api/v2/" +
              walletAddress +
              "/nft?chain=matic&format=decimal&token_addresses=" +
              nftAddress +
              "&limit=100",
            {
              method: "GET",
              headers: {
                accept: "*/*",
                "X-API-KEY": "szfmJTlrwUiXkXa5RTBPqVw3kAjKVkQ8NRgUjee7ONdRNzZrTlYJA8vuU6qwynS5",
              },
            }
          );
          return await responseRaw.json();
        },
        async hasETHTTBalance(walletAddress: string): Promise<boolean> {
          const responseRaw = await fetch(
            "https://deep-index.moralis.io/api/v2/" +
              walletAddress +
              "/erc20?chain=eth&token_addresses=0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
            {
              method: "GET",
              headers: {
                accept: "*/*",
                "X-API-KEY": "szfmJTlrwUiXkXa5RTBPqVw3kAjKVkQ8NRgUjee7ONdRNzZrTlYJA8vuU6qwynS5",
              },
            }
          );
          return await responseRaw.json();
        },
        async usersRewards(walletAddress: string): Promise<any> {
          let chainRewardAddress: string;
          if (currentChainId == 137) {
            chainRewardAddress = rewardAddress;
          } else if (currentChainId == 1) {
            chainRewardAddress = ethRewardAddress;
          } else {
            return;
          }

          const contract = getContract(TTRewards, chainRewardAddress);
          const rewards = await contract.methods.usersRewards(walletAddress).call();

          return {
            rewardsEarned: rewards.rewardsEarned / Math.pow(10, decimals),
            rewardsClaimed: rewards.rewardsClaimed / Math.pow(10, decimals),
          };
        },
        claimRewards: () => {
          let chainRewardAddress: string;
          if (currentChainId == 137) {
            chainRewardAddress = rewardAddress;
          } else if (currentChainId == 1) {
            chainRewardAddress = ethRewardAddress;
          } else {
            return;
          }
          const contract = getContract(TTRewards, chainRewardAddress);
          return contract.methods.claimRewards().send({ from: walletAddress });
        },
      }}
    >
      {props.children}
    </Web3TeamTokenContext.Provider>
  );
}

/**
 * Check if the transaction was success based on the receipt.
 *
 * https://ethereum.stackexchange.com/a/45967/620
 *
 * @param receipt Transaction receipt
 */
export function isSuccessfulTransaction(receipt: any): boolean {
  return receipt.status == "0x1" || receipt.status == 1;
}
