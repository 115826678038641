import moment from "moment";
import { sportsMapping } from "../assets/Data";

export const getNftAttribute = (nft, attributeName) => {
  return nft.attributes.find((attribute) => attribute.trait_type === attributeName)?.value;
};

export const applyNftFilters = (salesData, nfts, searchParams) => {
  return nfts
    .filter(function (nfts) {
      return nfts.tokenId < 1002000 || nfts.tokenId > 1002100;
    })
    .map((nft) => {
      const allSalesOrders = salesData.filter((sale) => sale.tokenId === nft.tokenId);
      const curSalesData = salesData
        .filter((sale) => sale.tokenId === nft.tokenId && sale.sellQuantity - sale.buyQuantity > 0)
        .sort((a, b) => a.price - b.price);

      const now = moment();
      const startTime = curSalesData.length > 0 ? moment(Number(curSalesData[0].startTime) * 1000) : null;

      return {
        tokenId: nft.tokenId,
        image: nft.rawMetadata.image,
        name: nft.rawMetadata.name,
        points: "",
        priceparsentage: curSalesData.length > 0 ? Math.round(curSalesData[0].price / Math.pow(10, 18)) : "",
        sport: sportsMapping[getNftAttribute(nft.rawMetadata, "Sport")],
        team: getNftAttribute(nft.rawMetadata, "Team"),
        league: getNftAttribute(nft.rawMetadata, "Sport"),
        season: getNftAttribute(nft.rawMetadata, "Season"),
        sales: curSalesData,
        startTime: startTime,
        // comingSoon: allSalesOrders.length <= 0 || startTime > now,
        comingSoon: startTime > now,
      };
    })
    .filter((nft) => {
      if (searchParams.has("Sport") && searchParams.get("Sport") !== "all" && nft.sport !== searchParams.get("Sport")) {
        return false;
      }

      if (searchParams.has("Team") && searchParams.get("Team") !== "all" && nft.team !== searchParams.get("Team")) {
        return false;
      }

      if (
        searchParams.has("League") &&
        searchParams.get("League") !== "all" &&
        nft.league !== searchParams.get("League")
      ) {
        return false;
      }

      if (
        searchParams.has("Season") &&
        searchParams.get("Season") !== "all" &&
        nft.season !== searchParams.get("Season")
      ) {
        return false;
      }

      if (
        searchParams.has("Availability") &&
        searchParams.get("Availability") !== "all" &&
        ((!nft.comingSoon && searchParams.get("Availability") === "coming_soon") ||
          (nft.comingSoon && searchParams.get("Availability") === "available"))
      ) {
        return false;
      }

      if (
        searchParams.has("Search") &&
        searchParams.get("Search") != "" &&
        (nft.name == "" || nft.name.toLowerCase().indexOf(searchParams.get("Search").toLowerCase()) === -1)
      ) {
        return false;
      }

      if (
        searchParams.has("SortBy") &&
        (searchParams.get("SortBy") == "PriceASC" || searchParams.get("SortBy") == "PriceDESC") &&
        nft.priceparsentage == ""
      ) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (searchParams.has("SortBy") && searchParams.get("SortBy") == "PriceASC") {
        return Number(a.priceparsentage) - Number(b.priceparsentage);
      }

      if (searchParams.has("SortBy") && searchParams.get("SortBy") == "PriceDESC") {
        return Number(b.priceparsentage) - Number(a.priceparsentage);
      }

      if (!searchParams.has("SortBy") || searchParams.get("SortBy") == "Name" || searchParams.get("SortBy") == "") {
        return a.name.localeCompare(b.name);
      }

      //return Number(a.priceparsentage) - Number(b.priceparsentage);
      return 0; // Don't sort, just keep the existing order
    });
};
