import EthImg from "./Eth.png";

export const sportsMapping = {
  "NFL": "American Football",
  "MLB": "Baseball",
  "NHL": "Hockey",
  "NBA": "Basketball",
};

export const optionsSelect = [
  // {
  //   label: "Status",
  //   options: [
  //     { value: "All", label: "All" },
  //     { value: "Available", label: "Available" },
  //     { value: "New", label: "New" },
  //   ],
  // },
  {
    label: "Sport",
    options: [{ value: "all", label: "All" },
      ...Object.values(sportsMapping).map((s) => {
        return {
          value: s,
          label: s,
        };
      })],
  },
  {
    label: "League",
    options: [
      { value: "all", label: "All" },
      { value: "NFL", label: "NFL" },
      { value: "NHL", label: "NHL" },
      { value: "NBA", label: "NBA" },
      { value: "BBL", label: "BBL" },
      { value: "PGA", label: "PGA" },
    ],
  },
  // {
  //   label: "Team",
  //   options: [
  //     { value: "all", label: "All" },
  //     { value: "1", label: "One" },
  //     { value: "2", label: "Two" },
  //     { value: "3", label: "Three" },
  //     { value: "4", label: "Four" },
  //   ],
  // },
  {
    label: "Season",
    options: [
      { value: "all", label: "All" },
      { value: "2023-2024", label: "2023-2024" },
    ],
  },
  {
    label: "Availability",
    options: [
      { value: "all", label: "All" },
      { value: "available", label: "Available" },
      { value: "coming_soon", label: "Coming Soon" },
    ],
  },
  // {
  //   label: "Trible",
  //   options: [
  //     { value: "all", label: "All" },
  //     { value: "1", label: "One" },
  //     { value: "2", label: "Two" },
  //     { value: "3", label: "Three" },
  //     { value: "4", label: "Four" },
  //   ],
  // },
  // {
  //   label: "Mana",
  //   options: [
  //     { value: "all", label: "All" },
  //     { value: "1", label: "One" },
  //     { value: "2", label: "Two" },
  //     { value: "3", label: "Three" },
  //     { value: "4", label: "Four" },
  //   ],
  // },
];

export const sortableSelct1 = [
  {
    id: "1",
    options: [
      {
        value: "ETH",
        label: (
          <>
            <img src={EthImg} alt="Ethereum" /> ETH
          </>
        ),
      },
      { value: "1", label: "One" },
      { value: "2", label: "Two" },
      { value: "3", label: "Three" },
      { value: "4", label: "Four" },
    ],
  },
];

export const sortSelect2 = [
  {
    id: "2",
    options: [
      { value: "", label: "Sort by"},
      { value: "PriceASC", label: "Price low to high" },
      { value: "PriceDESC", label: "Price high to low" },
      { value: "Name", label: "Name" },
      // { value: "Most earned", label: "Most earned" },
    ],
  },
];
