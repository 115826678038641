import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { warginghandl } from "../redux/WorningSlice";
import { walletModel } from "./Styles";
import Wrnimg from "../assets/wrn.png";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";


const WarningTsti = () => {

  const matchpddis50 = useMediaQuery("(max-width:500px)");
  const dispatch = useDispatch();
  const warnings = useSelector((state) => state.warning.warning);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(warginghandl(false));
  };

  useEffect(() => {
    if (warnings) {
      setOpen(true);
    }
  }, [warnings]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...walletModel,width:matchpddis50 ?"70%":"400px",textAlign:"center"}}>
          <Box style={{ width: "fit-content", margin: "1rem auto" }}>
            <img src={Wrnimg} alt="icons" />
          </Box>
          <Box>
            <Typography variant="body1" fontSize={20}>
              There was an error downloading version{" "}
            </Typography>
            <Typography variant="body1" fontSize={20}>
              {" "}
              information. Please try again in few minutes
            </Typography>
          </Box>
          <Box  style={{ width: "fit-content", margin: "1rem auto" }}>
            <Button
              variant="contained"
              style={{ background: "#F70329", width: "100px" }}
              onClick={handleClose}
            >
              ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default WarningTsti;
