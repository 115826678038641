import React, { useEffect } from "react";
import { Alchemy } from "alchemy-sdk";
import { alchemyApiKey, alchemyNetwork, nftAddress } from "../config";
import { Web3TeamTokenContext } from "./Web3TeamToken";
import { ttTokenAddress } from "../config";
export const AlchemyApiContext = React.createContext(null);

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
export function AlchemyApiContextProvider(props: any) {
  const config = {
    apiKey: alchemyApiKey[137],
    network: alchemyNetwork[137],
  };

  // const ethConfig = {
  //   apiKey: alchemyApiKey[1],
  //   network: alchemyNetwork[1],
  // };

  const alchemy = new Alchemy(config);
  // const ethAlchemy = new Alchemy(ethConfig);

  return (
    <AlchemyApiContext.Provider
      value={{
        getNfts: async (owner: string) => {
          if (owner) {
            return await alchemy.nft.getNftsForOwner(owner, {
              contractAddresses: [nftAddress],
              omitMetadata: false,
            });
          }

          return await alchemy.nft.getNftsForContract(nftAddress, {
            omitMetadata: false,
          });
        },
        // getEthTTBalance: async (owner: string) => {
        //   if (owner) {
        //     return await alchemy.core.getTokenBalances(owner, [ttTokenAddress]);
        //   }
        // },
      }}
    >
      {props.children}
    </AlchemyApiContext.Provider>
  );
}
