import React, { useEffect, Component } from "react";
import NavbarApp from "../components/NavbarApp";
import { MinHomeBox, typoGraph1, BoxEth, heroBox } from "../components/Styles";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import gptImg from "../assets/gpt.png";
import Ethimg from "../assets/Eth.png";
import imgTTtiny from "../assets/TT-tiny.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import iconDiscord from "../assets/discord.png";
import iconTelegram from "../assets/TelegramTiny.png";
import walitimg from "../assets/walit.png";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Sortable from "../components/Sortable";
import Cards from "../components/Cards";
import CardList from "../components/CardList";
import RewardHistory from "../components/RewardHistory";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/SidbarSlice";
import { tabsStyle } from "../components/Styles";
import { Swap } from "../components/Swap";
import FlipCountdown from "@rumess/react-flip-countdown";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const matchpdding1 = useMediaQuery("(max-width:600px)");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: matchpdding1 ? 10 : 20 }}>
          <Box variant="div">{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Home = () => {
  const matchpdding1 = useMediaQuery("(max-width:600px)");
  const matchpddisml = useMediaQuery("(max-width:450px)");
  const matchpddis70 = useMediaQuery("(max-width:470px)");
  const matchspace = useMediaQuery("(max-width:900px)");
  const matches14 = useMediaQuery("(max-width:1600px)");
  //  const [value, setValue] = React.useState(0);
  const [value, setValue] = React.useState(window.location.href.indexOf("buy") !== -1 ? 2 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const sidbarset = useSelector((state) => state.sidebar.sidebar);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(toggleSidebar(false));
  };

  useEffect(() => {
    if (sidbarset) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sidbarset]);

  return (
    <div>
      <NavbarApp />
      <Box
        className="heroImageBox"
        style={{
          ...MinHomeBox,
          padding: matchpddisml ? "0px 5rem" : matchpdding1 ? "0px 2rem" : "0px 2rem",
          justifyContent: "center",
          textAlign: matchpddis70 ? "center" : "inherit",
        }}
      >
        {/* <Button
          variant="contained"
          style={{
            background: "rgba(0, 0, 0, 0.72)",
            width: "fit-content",
            margin: matchpddis70 ? "0px auto" : "0px",
          }}
        >
          <ArrowBackIosIcon style={{ width: "15px" }} />
          <img src={gptImg} alt="icons" /> &nbsp; God Unchained
        </Button> */}

        <Box
          style={{
            display: "block",
            alignItems: "left",
            paddingTop: "1%",
            gap: "10px",
          }}
        >
          <Box style={heroBox}>
            {/* <FlipCountdown
              titlePosition='bottom'
              hideYear
              hideMonth
              dayTitle=' '
              hourTitle=' '
              minuteTitle=' '
              secondTitle=' '
              size= {matches14? "small": "medium"}
              endAt={"2023-09-26 16:00:00"} // Date/Time
              style={{marginRight:"auto"}}
            /> */}

            <Typography
              variant="h"
              style={{
                ...typoGraph1,
                fontSize: matchpddisml ? "15px" : matches14 ? "15px" : "30px",
                margin: matchpddis70 ? "0px auto" : "0px",
              }}
            >
              NHL TeamNFTs Now Available. One Million Bonus TT Rewards!
            </Typography>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <Box style={{ ...BoxEth, margin: matchpddis70 ? "0px auto" : "0px" }}>
                {/* <Typography variant="body1">
                  <img src={imgTTtiny} alt="icons" /> 500 <br /> floor
                </Typography> */}
                <a href="https://app.teamtoken.com/buy" target="_blank" rel="noreferrer">
                  <Button
                    variant="contained"
                    style={{
                      background: "#000000",
                      border: "1px solid red",
                      width: "160px",
                    }}
                  >
                    Buy TeamToken
                  </Button>
                </a>
              </Box>
              <Box style={{ ...BoxEth, margin: matchpddis70 ? "0px auto" : "0px" }}>
                <a href="https://t.me/teamtoken" target="_blank" rel="noreferrer">
                  <img src={iconTelegram} alt="icons" style={{ cursor: "pointer" }} />
                </a>
                <a href="https://discord.teamtoken.com/" target="_blank" rel="noreferrer">
                  <img src={iconDiscord} alt="icons" style={{ cursor: "pointer" }} />
                </a>
                <a href="https://twitter.com/TeamTokenCrypto" target="_blank" rel="noreferrer">
                  <TwitterIcon
                    style={{
                      opacity: "0.6",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
          <Tabs
            value={value}
            indicatorColor="secondary"
            classes={{ indicator: "custom-indicator" }}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ background: "#0A2334", padding: "0px 5px 10px 5px" }}
          >
            <Tab label="Market" {...a11yProps(0)} style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }} />
            <Tab label="My TeamNFTs" {...a11yProps(1)} style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }} />
            {/* <Tab
              label="Activity"
              {...a11yProps(1)}
              style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }}
            />
            <Tab
              label="Assets"
              {...a11yProps(2)}
              style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }}
            />
            <Tab
              label="Stats"
              {...a11yProps(3)}
              style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }}
            /> */}
            <Tab label="Buy TT" {...a11yProps(2)} style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }} />
            <Tab
              label="Advanced Mode"
              {...a11yProps(3)}
              style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }}
            />
            <Tab
              label="Reward History"
              {...a11yProps(4)}
              style={{ ...tabsStyle, minWidth: matchpddisml ? "64px" : "" }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Sortable />
            <Cards />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Sortable />
            <Cards onlyMine={true} />
          </Box>
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          Item four
        </CustomTabPanel> */}
        <CustomTabPanel value={value} index={2}>
          <h1 style={{ display: "flex", justifyContent: "center" }}>Buy Now!</h1>
          <Swap />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <h1 style={{ display: "flex", justifyContent: "center" }}>Advanced Mode</h1>
          <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Sortable />
            <CardList />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <h1 style={{ display: "flex", justifyContent: "center" }}>Reward History</h1>
          <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <RewardHistory />
          </Box>
        </CustomTabPanel>
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        ></Backdrop>
      </div>
    </div>
  );
};

export default Home;
