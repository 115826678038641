import React, { useContext } from "react";
// import { usePublicClient } from "wagmi";

import { darkTheme, SwapWidget, Theme } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";
// import tokenList from '../../assets/tokenList.json';
// import { Web3TeamTokenContext } from "../../context/Web3TeamToken";
import { Web3TeamTokenContext } from "../../context/Web3TeamToken";
// import { useAccount } from "wagmi";

//const TOKEN_LIST = 'https://bafybeigfrqindue4ke4zvbtdt4dp3v3o5mqgguud2mwmvkzk7ct73dcg2q.ipfs.dweb.link/'
const TEAMTOKEN = "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82";

const TOKEN_LIST = [
  {
    chainId: 137,
    address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
    name: "TeamToken",
    symbol: "TT",
    decimals: 18,
    logoURI: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png",
  },
  {
    chainId: 1,
    address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
    name: "TeamToken",
    symbol: "TT",
    decimals: 18,
    logoURI: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png",
  },
];
// const TOKEN_LIST = [
//   {
//     name: "Wrapped Ether",
//     address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
//     symbol: "WETH",
//     decimals: 18,
//     chainId: 1,
//     // eslint-disable-next-line max-len
//     logoURI:
//       "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
//   },
//   {
//     chainId: 1,
//     address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
//     name: "TeamToken",
//     symbol: "TT",
//     decimals: 18,
//     logoURI: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png",
//   },
//   {
//     chainId: 137,
//     address: "0xdF906f6FD89ce60c16bAEd3C96CEB08BCA65AD82",
//     name: "TeamToken",
//     symbol: "TT",
//     decimals: 18,
//     logoURI: "https://raw.githubusercontent.com/TeamTokenCrypto/assets/main/images/teamtoken-icon-200.png",
//   },
//   {
//     chainId: 1,
//     name: "Wrapped Bitcoin",
//     address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
//     decimals: 8,
//     symbol: "WBTC",
//     logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png",
//   },
//   {
//     chainId: 1,
//     name: "Tether USDt",
//     address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
//     decimals: 18,
//     symbol: "USDT",
//     logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
//   },
//   {
//     chainId: 1,
//     name: "Dai",
//     address: "0x6b175474e89094c44da98b954eedeac495271d0f",
//     decimals: 18,
//     symbol: "DAI",
//     logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
//   },
//   {
//     chainId: 1,
//     name: "USD Coin",
//     address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
//     decimals: 6,
//     symbol: "USDC",
//     logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
//   },
// ];

// const customTeamTokenTheme: Theme = {
//   ...darkTheme,
//   // primary: '#000',
//   // secondary: '#666',
//   // interactive: '#0089EC',
//   // container: '#FFF',
//   // module: '#E7E7E7',
//   // accent: '#3D3B31',
//   // outline: '#343D3A',
//   // dialog: '#FFF',
//   // fontFamily: 'Verdana',
//   // borderRadius: 2,
//   // tokenColorExtraction: false, // Enable color extraction of the output token
// };

// const theme: Theme = {
//   primary: '#000',
//   secondary: '#666',
//   interactive: '#0089EC',
//   container: '#FFF',
//   module: '#E7E7E7',
//   accent: '#3D3B31',
//   outline: '#343D3A',
//   dialog: '#FFF',
//   fontFamily: 'Verdana'
// }

const jsonRpcUrlMap = {
  1: ["https://eth-mainnet.g.alchemy.com/v2/WwkR7OgUeWBsKscvvruAsi7aSizCV5yk"],
  5: ["https://goerli.g.alchemy.com/v2/2fhnXDzD-Re5KoJlDXFNjrX70VbYxO8v"],
  137: ["https://polygon-mainnet.g.alchemy.com/v2/QxrsctzBA3_AORIxj-a5E0VdFJJ4DWZf"],
};

const Swap = () => {
  // const { web3Connection } = useContext(Web3TeamTokenContext);
  // const { connector: activeConnector, isConnected } = useAccount();
  // const publicClient = usePublicClient({
  //   chainId: 1,
  // });
  // console.log(connector);

  const { currentChainId, web3Connection, provider } = React.useContext(Web3TeamTokenContext);

  let route = "ethmatic";
  if (currentChainId == 137) {
    route = "maticeth";
  }

  return (
    <>
      <div className="Uniswap" style={{ display: "flex", justifyContent: "center" }}>
        <SwapWidget
          //   defaultInputTokenAddress="NATIVE"
          tokenList={TOKEN_LIST}
          defaultInputAmount="100"
          // defaultChainId={137}

          // provider={provider}
          //  jsonRpcUrlMap={jsonRpcUrlMap}
          // convenienceFee={100}
          // convenienceFeeRecipient="0xbac09bCd3C11168AE39028c145710Cc862E84d7C"
          theme={darkTheme}
          defaultOutputTokenAddress={TEAMTOKEN}
          brandedFooter={false}
          //  width="100%"
          // permit2={true}
        />
      </div>
      {/* <div className="py-4" style={{ display: "flex", justifyContent: "center" }}>
        <iframe
          src={`https://umbria.network/widgetv2/?route=${route}`}
          width="960" height="600" scrolling="no"
        >
        </iframe>
      </div> */}
    </>
  );
};

export default Swap;
