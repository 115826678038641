import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import Modal from "@mui/material/Modal";
import {
  popupBox,
  kigsname,
  stptag,
  kigsown,
  mainBoxkig,
  forsalBox,
  forsalechildBox,
  selctEhtstyled,
  lsticonpopup,
  closeBox,
  poupbtn,
  poupbtnrow,
  popupcloseincon,
  popupcard2,
  popupbox2border,
  Boxesmainbox,
  BuySellbox,
} from "./Styles";
import imgrod from "../assets/round.png";
import imgred from "../assets/red.png";
import imgvat from "../assets/vat.png";
import imgETH from "../assets/Eth.png";
import imgTTtiny from "../assets/TT-tiny.png";

import { createSalesOrdersFor } from "../util/OrderHelper";
import { sortableSelct1 } from "../assets/Data";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import imgsms from "../assets/check.png";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useMediaQuery from "@mui/material/useMediaQuery";
import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";
import { Web3TeamTokenContext } from "../context/Web3TeamToken";
import walitimg from "../assets/walit.png";
import { chainId, marketplaceAddress, ttTokenAddress } from "../config";
import SimpleDateTime from "react-simple-timestamp-to-date";
import { useBalance } from "wagmi";




const CardDetailsPopup = ({ val, setVal }) => {
  const matchwdth = useMediaQuery("(max-width:850px)");
  const matchwdthBuySell = useMediaQuery("(max-width:1150px)");
  const matchwdth48 = useMediaQuery("(max-width:850px)");
  const {
    web3Connected,
    currentChainId,
    switchToEthChain,
    walletAddress,
    web3Connect,
    getAllowance,
    ethBalance,
    approveContract,
    isNftApproved,
    approveNft,
    buyTeamNft,
    buyTeamNftETH,
    buyBulkTeamNft,
    cancelList,
    listTeamNft,
    hasNFT,
    balance,
    price: usdPrice,
    ethPrice,
    maticPrice
  } = React.useContext(Web3TeamTokenContext);
  const [allowance, setAllowance] = useState(0);
  const [nftApproval, setNftApproval] = useState(false);
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(1);
  const [ownedNfts, setOwnedNfts] = useState(0);
  const handleClose = () => setVal(null);
  const [teamNftsData, setTeamNftsData] = useState(0);
  const [activeTab, setActiveTab] = useState("tab1");
  const [buySellTab, setBuySellTab] = useState("buy");


  const { data: balanceData } = useBalance({
    address: walletAddress,
  });

  function abbreviateAddress(walletAddress, beforeLength = 6, afterLength = 4) {
    return (
      walletAddress.substring(0, beforeLength) +
      "..." +
      walletAddress.substring(walletAddress.length - afterLength, walletAddress.length)
    );
  }

  useEffect(() => {
    if (val !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [val]);

  useEffect(() => {
    setAmount(1);
    if (val) {
      setTotal(calculateBulkOrderCost(val.sales, 1));
    }
  }, [open]);

  function sumUpSales(arr) {
    let sum = 0;
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i].sellQuantity - arr[i].buyQuantity;
      }
    }
    return sum;
  }

  function calculateBulkOrderCost(sales, quantityToBuy) {
    var salesOrders = createSalesOrdersFor(sales, quantityToBuy);
    // create a variable for the sum and initialize it
    let sum = 0;

    // iterate over each item in the array
    if (salesOrders) {
      for (let i = 0; i < salesOrders.length; i++) {
        sum += salesOrders[i].totalCostAtThisPricePoint;
        // if (salesOrders[i].quantity > 0) {
        //   contractData.push(salesOrders[i]);
        // }
      }
    }
    return sum;
  }

  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    if (currentChainId != 137) {
      return;
    }

    const checkAllowance = () => {
      getAllowance(ttTokenAddress, marketplaceAddress).then((allowance) => {
        setAllowance(allowance);
      });

      isNftApproved(marketplaceAddress).then((approved) => {
        setNftApproval(approved);
      });
    };
    const interval = setInterval(() => {
      checkAllowance();
    }, 15000);
    checkAllowance();

    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    if (val === null) {
      return;
    }

    Promise.all([fetch("https://api.teamtoken.com/teamnft/" + val.tokenId).then((response) => response.json())]).then(
      ([teamnftdata]) => {
        setTeamNftsData({
          reward_amount: teamnftdata.total_earned,
          reward_pool: teamnftdata.reward_pool,
          total_owners: teamnftdata.owners,
          reward_history: teamnftdata.reward_history,
          transactions: teamnftdata.transactions,
        });
      }
      // .sort((a, b) => b.gametime - a.gametime)
    );
  }, [val]);
  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    if (val === null) {
      return;
    }

    if (!open) {
      return;
    }

    const getOwnedNfts = () => {
      hasNFT(walletAddress).then((nfts) => {
        if (!nfts || !nfts.result) {
          setOwnedNfts(0);
          return;
        }

        const curOwnedNfts = nfts.result.find((nft) => nft.token_id === val.tokenId);
        if (curOwnedNfts) {
          setOwnedNfts(Number(curOwnedNfts.amount));
        } else {
          setOwnedNfts(0);
        }
      });
    };
    const interval = setInterval(() => {
      getOwnedNfts();
    }, 30000);
    getOwnedNfts();

    return () => clearInterval(interval);
  }, [val, open, walletAddress]);

  const Tooltipshow = () => {
    return (
      <div>
        <Tippy
          className="tippyset"
          content={
            <span>
              Not all offers are for your specific NFT(s). An offer can only be created for the current cheapest
              listing. Changing your offer will update it to offer to the current cheapest seller. All offers are shown
              to inform sellers what realistic prices buyers are offering.
            </span>
          }
        >
          <img src={imgsms} alt="icons" id="myButton" />
        </Tippy>
      </div>
    );
  };

  if (!val) {
    return;
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={popupBox}>
            <Box style={closeBox}>
              <CloseIcon style={popupcloseincon} onClick={handleClose} />
            </Box>
            <Typography className="fontset" fontSize={35} fontWeight={600} variant="body1">
              {val.name}
            </Typography>
            <Box
              style={{
                ...mainBoxkig,
                flexDirection: matchwdth48 ? "column" : "row",
              }}
            >
              <Box>
                <img
                  src={val.image}
                  alt=""
                  style={{ width: "320px", background: "white", padding: "1px", borderRadius: "10px" }}
                />
                {/* <Box
                  style={{ ...kigsown, width: matchwdth48 ? "auto" : "225px" }}
                >
                  <Typography variant="body2">Owned</Typography>
                  <Typography variant="body2">0/100</Typography>
                </Box> */}
              </Box>

              <Box style={{ ...stptag, width: matchwdth48 ? "100%" : "100%" }}>
                <Box style={{ ...kigsname, width: matchwdth48 ? "100%" : "100%" }}>
                  <Box style={{ ...stptag, paddingLeft: "1rem" }}>
                    <Typography variant="body2">Sport:</Typography>
                    <Typography variant="body2">League:</Typography>
                    <Typography variant="body2">Year:</Typography>
                  </Box>
                  <Box style={stptag}>
                    <Typography variant="body2">{val.sport}</Typography>
                    <Typography variant="body2">NFL</Typography>
                    <Typography variant="body2">{val.season}</Typography>
                  </Box>
                  <Box style={stptag}>
                    <Typography variant="body2">Rewards Earned:</Typography>
                    <Typography variant="body2">Rewards In Pool:</Typography>
                    <Typography variant="body2">Owned:</Typography>
                  </Box>
                  <Box style={{ ...stptag, paddingRight: "1rem" }}>
                    <Typography variant="body2">
                      {teamNftsData.reward_amount &&
                        new Intl.NumberFormat("en-GB").format(Math.round(teamNftsData.reward_amount)) + " "}
                      TT
                    </Typography>
                    <Typography variant="body2">
                      {teamNftsData.reward_pool &&
                        new Intl.NumberFormat("en-GB").format(Math.round(teamNftsData.reward_pool)) + " "}
                      TT
                    </Typography>
                    <Typography variant="body2">{ownedNfts}/100</Typography>
                  </Box>
                </Box>

                <Box
                  style={{
                    ...BuySellbox,
                    flexDirection: matchwdth ? "column" : "row",
                  }}
                >
                  <Box style={popupbox2border}>
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", borderBottom: "0px solid #ddd" }}>
                        <div
                          style={{
                            flex: 1,
                            padding: "10px",
                            textAlign: "center",
                            cursor: "pointer",
                            marginBottom: "20px",
                            fontSize: "1.5em",
                            borderBottom: buySellTab === "buy" ? "2px solid #F70329" : "none",
                          }}
                          onClick={() => {
                            setBuySellTab("buy");
                            setAmount(1);
                          }}
                        >
                          Buy
                        </div>
                        {ownedNfts > 0 && (
                          <>
                            <div
                              style={{
                                flex: 1,
                                padding: "10px",
                                textAlign: "center",
                                marginBottom: "20px",
                                cursor: "pointer",
                                fontSize: "1.5em",
                                borderBottom: buySellTab === "sell" ? "2px solid #F70329" : "none",
                              }}
                              onClick={() => {
                                setBuySellTab("sell");
                                setAmount(1);
                                setPrice(
                                  val.sales[0]?.price ? Math.round(val.sales[0].price / Math.pow(10, 18)) - 100 : 0
                                );
                              }}
                            >
                              Sell
                            </div>
                          </>
                        )}
                      </div>
                      <div style={{ padding: "10px" }}>
                        {buySellTab === "buy" && ( //-------------------- BUY -------------------------------
                          <div>
                            <div style={{ display: "flex" }}>
                              <Box style={{ width: "50%", marginBottom: "1rem" }}>
                                <Typography variant="body2" style={{ color: "darkgray" }}>
                                  Current Lowest Price:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#fff", fontSize: "1.6rem", fontWeight: "600" }}
                                >
                                  {val.sales[0]
                                    ? new Intl.NumberFormat("en-GB").format(
                                      Math.round(val.sales[0].price / Math.pow(10, 18))
                                    ) + " TT"
                                    : "Sold out"}
                                </Typography>
                              </Box>
                              {balance && (
                                <Box style={{ width: "49%", textAlign: "right", marginBottom: "1rem" }}>
                                  <Typography variant="body2" style={{ color: "darkgray" }}>
                                    Balance
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#fff", fontSize: "1.6rem", fontWeight: "600" }}
                                  >
                                    {balance
                                      ? new Intl.NumberFormat("en-GB").format(Math.round(balance)) + " TT"
                                      : null}
                                  </Typography>
                                </Box>
                              )}
                              {balanceData && (
                                <Box style={{ width: "49%", textAlign: "right", marginBottom: "1rem" }}>
                                  <Typography variant="body2" style={{ color: "darkgray" }}>
                                    {balanceData.symbol}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#fff", fontSize: "1.6rem", fontWeight: "600" }}
                                  >
                                    {balanceData.formatted ? new Intl.NumberFormat("en-GB")
                                      .format(balanceData.formatted) + " " + balanceData.symbol : null}
                                  </Typography>
                                </Box>
                              )}
                            </div>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                gap: "10px",
                                width: "100%",
                                flexDirection: matchwdthBuySell ? "column" : "row",
                              }}
                            >
                              {!val.comingSoon && web3Connected && (
                                <>
                                  <span> </span>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        background: "rgba(0,0,0,0.65)",
                                        width: "120px",
                                        display: "flex",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          background: "#6D6E70",
                                          borderRadius: "4px",
                                          minWidth: "40px",
                                          padding: "7px 7px",
                                          color: "#fff",
                                        }}
                                        onClick={() => {
                                          if (amount < 1 || amount > sumUpSales(val.sales)) {
                                            return;
                                          }
                                          setTotal(calculateBulkOrderCost(val.sales, amount - 1));
                                          setAmount(amount - 1);
                                        }}
                                      >
                                        -
                                      </Button>
                                      <input
                                        style={{
                                          background: "rgba(0,0,0,0.65)",
                                          color: "#fff",
                                          minWidth: "40px",
                                          border: "none",
                                          textAlign: "center",
                                          flexGrow: 1,
                                        }}
                                        type="number"
                                        min={0}
                                        max={Math.max(
                                          10,
                                          val.sales[0] ? val.sales[0].sellQuantity - val.sales[0].buyQuantity : 10
                                        )}
                                        value={amount}
                                        onChange={(e) => {
                                          const inputValue = Number(e.target.value);

                                          if (inputValue > sumUpSales(val.sales)) {
                                            setAmount(sumUpSales(val.sales));
                                            setTotal(calculateBulkOrderCost(val.sales, sumUpSales(val.sales)));
                                          } else {
                                            setAmount(inputValue);
                                            setTotal(calculateBulkOrderCost(val.sales, inputValue));
                                          }
                                        }}
                                      />
                                      <Button
                                        style={{
                                          background: "#6D6E70",
                                          borderRadius: "4px",
                                          minWidth: "40px",
                                          padding: "0px 7px",
                                          color: "#fff",
                                        }}
                                        onClick={() => {
                                          if (amount < 0 || amount >= sumUpSales(val.sales)) {
                                            return;
                                          }
                                          if (
                                            moment().isBefore(val.startTime.clone().add(24, "hours")) &&
                                            amount == 10
                                          ) {
                                            return;
                                          }
                                          setTotal(calculateBulkOrderCost(val.sales, amount + 1));
                                          setAmount(amount + 1);
                                        }}
                                      >
                                        +
                                      </Button>
                                    </Box>
                                    <Box>
                                      <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                        Quantity
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <div style={{ width: matchwdthBuySell ? "35%" : "25%" }}>
                                    <Button
                                      variant="contained"
                                      id={"buybutton"}
                                      style={{ background: "#F70329", width: "100%" }}
                                      onClick={() => {
                                        if (!val.sales[0]) {
                                          alert("No sell orders found");
                                          return;
                                        }

                                        if (allowance < val.sales[0].price * amount) {
                                          approveContract(ttTokenAddress, marketplaceAddress);
                                          return;
                                        }

                                        if (!balance) {
                                          alert("No balance found");
                                          return;
                                        }

                                        if ((val.sales[0].price * amount) / Math.pow(10, 18) > Number(balance)) {
                                          alert("Insufficient balance");
                                          return;
                                        }

                                        buyTeamNft(val.sales[0]["_id"].sellId, amount);
                                      }}
                                    >
                                      {allowance >= val.sales[0]?.price * amount ? "Buy with TT" : "Approve to buy"}
                                    </Button>
                                    {val.sales[0] && amount > 0 && (
                                      <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                        Total price: {new Intl.NumberFormat("en-GB").format(Math.round(total))} TT
                                      </Typography>
                                    )}
                                  </div>
                                  <div style={{ width: matchwdthBuySell ? "35%" : "25%" }}>
                                    <Button
                                      id={"buywithethbutton"}
                                      variant="contained"
                                      style={{ background: "#F70329", width: "100%" }}
                                      onClick={() => {
                                        if (!val.sales[0]) {
                                          alert("No sell orders found");
                                          return;
                                        }

                                        if (!ethBalance) {
                                          alert("No ETH balance found");
                                          return;
                                        }

                                        // if ((((val.sales[0].price * amount) * usdPrice
                                        //   / ethPrice) / Math.pow(10, 18) * amount)
                                        //   > Number(ethBalance)) {
                                        //   alert("Insufficient balance");
                                        //   return;
                                        // }

                                        buyTeamNftETH(
                                          val.sales[0]["_id"].sellId,
                                          amount,
                                          val.sales[0]["tokenId"],
                                          (total * usdPrice) / maticPrice
                                        );
                                      }}
                                    >
                                      {"Buy with MATIC"}
                                    </Button>
                                    {val.sales[0] && amount > 0 && (
                                      <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                        Total price:{" "}
                                        {new Intl.NumberFormat("en-GB").format((total * usdPrice) / maticPrice)} MATIC
                                      </Typography>
                                    )}
                                  </div>
                                  <span> </span>
                                </>
                              )}
                              {!val.comingSoon && !web3Connected && (
                                <>
                                  <span> </span>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography variant="body2" style={{ color: "darkgray" }}>
                                      <Button
                                        variant="contained"
                                        style={{
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          background: "#F70329",
                                          border: "1px solid red",
                                          width: "180px",
                                          textTransform: "capitalize",
                                        }}
                                        //onClick={handelWallet}
                                        onClick={() => {
                                          web3Connect();
                                        }}
                                      >
                                        {" "}
                                        <img src={walitimg} alt="images" /> &nbsp; Connect Wallet
                                      </Button>
                                    </Typography>
                                  </Box>

                                  <span> </span>
                                </>
                              )}
                            </Box>
                          </div>
                        )}
                        {buySellTab === "sell" && ( // ------------------------- SELLLLL ------------------------------
                          <div>
                            <div style={{ display: "flex" }}>
                              <Box style={{ width: "50%", marginBottom: "1rem" }}>
                                <Typography variant="body2" style={{ color: "darkgray" }}>
                                  Current Lowest Price:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#fff", fontSize: "1.6rem", fontWeight: "600" }}
                                >
                                  {val.sales[0]
                                    ? new Intl.NumberFormat("en-GB").format(
                                      Math.round(val.sales[0].price / Math.pow(10, 18))
                                    ) + " TT"
                                    : "Sold out"}
                                </Typography>
                              </Box>
                              {balance && (
                                <Box style={{ width: "49%", textAlign: "right", marginBottom: "1rem" }}>
                                  <Typography variant="body2" style={{ color: "darkgray" }}>
                                    Balance
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#fff", fontSize: "1.6rem", fontWeight: "600" }}
                                  >
                                    {balance
                                      ? new Intl.NumberFormat("en-GB").format(Math.round(balance)) + " TT"
                                      : null}
                                  </Typography>
                                </Box>
                              )}
                            </div>

                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                gap: "18px",
                                width: "100%",
                                flexDirection: matchwdthBuySell ? "column" : "row",
                              }}
                            >
                              {ownedNfts > 0 && (
                                <>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <div style={{ width: "40%" }}>
                                      <input
                                        style={{
                                          background: "rgba(0,0,0,0.65)",
                                          color: "#fff",
                                          height: "35px",
                                          border: "none",
                                          borderRadius: "4px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                        type="number"
                                        min={0}
                                        value={price}
                                        onChange={(e) => {
                                          setPrice(Number(e.target.value));
                                        }}
                                      />
                                      <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                        List Price (TT)
                                      </Typography>
                                    </div>
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          background: "rgba(0,0,0,0.65)",
                                          width: "120px",
                                          display: "flex",
                                        }}
                                      >
                                        <Button
                                          style={{
                                            background: "#6D6E70",
                                            borderRadius: "4px",
                                            minWidth: "40px",
                                            padding: "7px 7px",
                                            color: "#fff",
                                          }}
                                          onClick={() => {
                                            if (amount <= 1) {
                                              return;
                                            }
                                            setAmount(amount - 1);
                                          }}
                                        >
                                          -
                                        </Button>
                                        <input
                                          style={{
                                            background: "rgba(0,0,0,0.65)",
                                            color: "#fff",
                                            minWidth: "40px",
                                            border: "none",
                                            textAlign: "center",
                                            flexGrow: 1,
                                          }}
                                          type="number"
                                          min={1}
                                          max={ownedNfts}
                                          value={amount}
                                          onChange={(e) => {
                                            const inputValue = Number(e.target.value);
                                            if (inputValue > ownedNfts) {
                                              setAmount(ownedNfts);
                                            } else {
                                              setAmount(Number(e.target.value));
                                            }
                                          }}
                                        />
                                        <Button
                                          style={{
                                            background: "#6D6E70",
                                            borderRadius: "4px",
                                            minWidth: "40px",
                                            padding: "0px 7px",
                                            color: "#fff",
                                          }}
                                          onClick={() => {
                                            if (amount >= ownedNfts) {
                                              return;
                                            }
                                            setAmount(amount + 1);
                                          }}
                                        >
                                          +
                                        </Button>
                                      </Box>
                                      <Box>
                                        <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                          Quantity
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>

                                  <div style={{ width: matchwdthBuySell ? "45%" : "35%" }}>
                                    <Button
                                      variant="contained"
                                      style={{ background: "#F70329", width: "100%" }}
                                      onClick={() => {
                                        if (!nftApproval) {
                                          approveNft(marketplaceAddress);
                                          return;
                                        }

                                        listTeamNft(val.tokenId, amount, price);
                                      }}
                                    >
                                      {nftApproval ? "List" : "Approve to list"}
                                    </Button>
                                    {val.sales[0] && amount > 0 && (
                                      <Typography variant="body2" style={{ color: "darkgray", textAlign: "center" }}>
                                        Total List value:
                                        {" " + new Intl.NumberFormat("en-GB").format(Math.round(price * amount))} TT
                                        {/* {Math.round((price * amount) / Math.pow(10, 18)) + " TT"} */}
                                      </Typography>
                                    )}
                                  </div>
                                </>
                              )}
                            </Box>
                          </div>
                        )}
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* // ---------------------------------------------------------BOTTOM---------------------------------- */}

            <Box
              style={{
                ...Boxesmainbox,
                flexDirection: matchwdth ? "column" : "row",
              }}
            >
              <Box style={popupbox2border}>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", borderBottom: "0px solid #ddd" }}>
                    <div
                      style={{
                        flex: 1,
                        padding: "10px",
                        textAlign: "center",
                        cursor: "pointer",
                        marginBottom: "20px",
                        fontSize: "1.3em",
                        borderBottom: activeTab === "tab1" ? "2px solid #F70329" : "none",
                      }}
                      onClick={() => setActiveTab("tab1")}
                    >
                      Listings
                    </div>
                    <div
                      style={{
                        flex: 1,
                        padding: "10px",
                        textAlign: "center",
                        marginBottom: "20px",
                        cursor: "pointer",
                        fontSize: "1.3em",
                        borderBottom: activeTab === "tab2" ? "2px solid #F70329" : "none",
                      }}
                      onClick={() => setActiveTab("tab2")}
                    >
                      Reward History
                    </div>
                    <div
                      style={{
                        flex: 1,
                        padding: "10px",
                        textAlign: "center",
                        marginBottom: "20px",
                        cursor: "pointer",
                        fontSize: "1.3em",
                        borderBottom: activeTab === "tab3" ? "2px solid #F70329" : "none",
                      }}
                      onClick={() => setActiveTab("tab3")}
                    >
                      Transaction History
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    {activeTab === "tab1" && (
                      <div>
                        <Box style={forsalBox}>
                          <Typography variant="caption" color="#6D6E70">
                            Price (TT)
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Price ($USD)
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Quantity
                          </Typography>
                          <Typography variant="caption" color="#6D6E70"></Typography>
                        </Box>
                        {val.sales.map((sale, index) => (
                          <Box key={index} style={forsalechildBox}>
                            <Typography variant="caption" style={{ width: "0px", marginLeft: "20px" }}>
                              {new Intl.NumberFormat("en-GB").format(Math.round(sale.price / Math.pow(10, 18)))}
                            </Typography>
                            <Typography variant="caption" style={{ width: "0px", marginLeft: "20px" }}>
                              ${(Math.round(sale.price / Math.pow(10, 18)) * usdPrice).toFixed(2)}
                            </Typography>
                            <Typography variant="caption" style={{ width: "0px", marginLeft: "40px" }}>
                              <span> {sale.sellQuantity - sale.buyQuantity}</span>
                            </Typography>
                            <Typography variant="caption" style={{ width: "0px", marginLeft: "0px" }}>
                              {sale.seller == walletAddress?.toLowerCase() && (
                                <Button
                                  variant="contained"
                                  // eslint-disable-next-line max-len
                                  style={{
                                    background: "#F70329",
                                    width: "150px",
                                    margin: "0px -150px ",
                                    visibility: matchwdth ? "hidden" : "visible",
                                  }}
                                  onClick={() => {
                                    cancelList(sale["_id"].sellId);
                                  }}
                                >
                                  Cancel Listing
                                </Button>
                              )}
                            </Typography>
                          </Box>
                        ))}
                      </div>
                    )}
                    {activeTab === "tab2" && teamNftsData.reward_history && (
                      <div>
                        <Box style={forsalBox}>
                          <Typography variant="caption" color="#6D6E70">
                            Winnings
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Game
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Gametime
                          </Typography>
                        </Box>
                        {teamNftsData.reward_history.map((game, index) => (
                          <Box key={index} style={forsalechildBox}>
                            <Typography variant="caption" style={{ width: "65px", marginLeft: "20px" }}>
                              {game.reward_amount + " TT"}
                            </Typography>
                            <Typography variant="caption" style={{ marginLeft: "20px" }}>
                              {game.team1} VS {game.team2}
                            </Typography>
                            <Typography variant="caption" style={{ width: "90px", marginLeft: "20px" }}>
                              <SimpleDateTime dateSeparator="-" showTime="0" format="YMD">
                                {game.gametime}
                              </SimpleDateTime>
                            </Typography>
                          </Box>
                        ))}
                      </div>
                    )}
                    {activeTab === "tab3" && teamNftsData.transactions && (
                      <div>
                        <Box style={forsalBox}>
                          <Typography variant="caption" color="#6D6E70">
                            Price
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            TeamNFTs
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Buyer
                          </Typography>
                          <Typography variant="caption" color="#6D6E70">
                            Transaction Time
                          </Typography>
                        </Box>
                        {teamNftsData.transactions.map((tx, index) => (
                          <Box key={index} style={forsalechildBox}>
                            <Typography variant="caption" style={{ width: "0px", marginLeft: "20px" }}>
                              {/* {tx.price.substring(0, tx.price.length - 18)} */}
                              {new Intl.NumberFormat("en-GB").format(Math.round(tx.price / Math.pow(10, 18)))}
                            </Typography>

                            <Typography variant="caption" style={{ width: "0px", marginLeft: "0px" }}>
                              {tx.amountOfToken}
                            </Typography>
                            <Typography variant="caption" style={{ width: "0px", marginRight: "15px" }}>
                              <a
                                href={"https://etherscan.com/tx/" + tx.transactionHash}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {abbreviateAddress(tx.buyer)}
                              </a>
                            </Typography>
                            <Typography variant="caption" style={{ marginLeft: "20px" }}>
                              <div>{moment(tx.blockTimestamp * 1000).format("YYYY-MM-DD HH:mm")}</div>
                            </Typography>
                          </Box>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Box>

            {!val.comingSoon && walletAddress && (
              <Box
                style={{
                  ...Boxesmainbox,
                  flexDirection: matchwdth ? "column" : "row",
                }}
              >
                {/* <Box style={popupbox2border}>
                <Typography
                  variant="body1"
                  fontSize={30}
                  fontWeight={500}
                  style={{ textAlign: "center" ,display:"flex",alignItems:"center",justifyContent:"center",gap:"6px"}}
                >
                  Offers <Tooltipshow />
                </Typography>
                <Box style={forsalBox}>
                  <Typography variant="caption" color="#6D6E70">
                    price
                  </Typography>
                  <Typography variant="caption" color="#6D6E70">
                    Amount
                  </Typography>
                </Box>
                <Box style={popupcard2}>
                  <Typography variant="body1">No Offer</Typography>
                </Box>
              </Box> */}
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
              }}
            >
              {/* <Typography variant="h6" style={lsticonpopup}>
                <AttachMoneyIcon /> Refer
              </Typography>
              <Typography variant="h6" style={lsticonpopup}>
                <FavoriteIcon /> Favorite
              </Typography> */}
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CardDetailsPopup;
